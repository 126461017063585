import {
  VehicleHistoryDTO,
  VehicleInformationClient,
} from '../api-clients/index';
import Loader from '../Loader';
import VehicleHistoryChart from '../VehicleHistory/VehicleHistoryChart';
import React, { ReactText, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  calculateFullYearLines,
  calculateLines,
  calculateTicks,
  convertToYearsArr,
} from './utils/utils';
import VehicleHistoryBox from './VehicleHistoryBox';
import { H1 } from '../styled';

const Container = styled.div`
  margin-top: 40px;
  width: calc(100% - 35px);
`;

const Title = styled(H1)``;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  //margin-left: 48px;
`;

export interface VehicleHistoryContainerProps {
  registrationNumber: string;
  caseId: number;
}

const VehicleHistoryContainer: React.FC<VehicleHistoryContainerProps> = ({
  registrationNumber,
  caseId,
}) => {
  const [vehicleHistory, setVehicleHistory] = useState<
    VehicleHistoryDTO | undefined
  >(undefined);
  const [xTicksArr, setXTicksArr] = useState<number[]>([]);
  const [yTicksArr, setYTicksArr] = useState<ReactText[]>([]);
  const [linesArr, setLinesArr] = useState<number[]>([]);
  const [fullYearLinesArr, setFullYearLinesArr] = useState<number[]>([]);
  const [yearsArr, setYearsArr] = useState<number[]>([]);

  useEffect(() => {
    const apiCall = async (
      caseId: number,
      reg: string,
      controller: AbortController
    ) => {
      const client = new VehicleInformationClient();
      client
        .getHistory(caseId, reg, controller.signal)
        .then((response) => {
          const ticks = calculateTicks(response);
          const lines = calculateLines(
            Number(ticks.x[ticks.x.length - 1]) - Number(ticks.x[0]),
            Number(ticks.x[0]),
            response.timeline!
          );
          const fullYearLines = calculateFullYearLines(
            Number(ticks.x[ticks.x.length - 1]) - Number(ticks.x[0]),
            Number(ticks.x[0]),
            response.timeline!
          );
          const years = convertToYearsArr(response.timeline!);

          setLinesArr(lines);
          setXTicksArr(ticks.x);
          setYTicksArr(ticks.y);
          setVehicleHistory(response);
          setFullYearLinesArr(fullYearLines);
          setYearsArr(years);
        })
        .catch((error) => console.log(error));
    };

    let controller: AbortController | undefined = undefined;

    if (registrationNumber && caseId) {
      controller = new AbortController();

      apiCall(caseId, registrationNumber, controller);
    }

    return () => {
      if (controller) {
        controller.abort();
      }
    };
  }, [caseId, registrationNumber]);

  return (
    <Container>
      <Title>FORDONSHISTORIK</Title>
      {vehicleHistory && vehicleHistory.timeline && (
        <Column>
          <VehicleHistoryChart
            timeline={vehicleHistory.timeline}
            xTicks={xTicksArr}
            yTicks={yTicksArr}
            height={380}
          />
          <VehicleHistoryBox
            lines={linesArr}
            fullYearLines={fullYearLinesArr}
            yearsArr={yearsArr}
            timeline={vehicleHistory.timeline}
          />
        </Column>
      )}
      {!vehicleHistory && <Loader />}
    </Container>
  );
};

export default VehicleHistoryContainer;
