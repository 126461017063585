import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { FirstColumnWidth, Margins } from '../../../utils/styles';
import CaseListComponent from '../CaseListComponent';
import ZebraList, { ZebraListProps } from '../../ZebraList/ZebraList';
import { initialRepairShopInformation } from './emptyData';
import { CasesClient, GetRepairShopDetailsResponse } from '../../api-clients';
import { CasesContext } from '../CasesContext';
import { Container, Divider, Spacer, SubContainer } from '../styles';
import { H1 } from '../../styled';
import Loader from '../../Loader';
import CaseFilterComponent from '../CaseFilterComponent';
import PaginationComponent from '../PaginationComponent';

const RepairShopContainer = styled(Container)`
  padding-bottom: 60px;
`;

const Title = styled(H1)`
  margin-top: ${Margins.Double};
  margin-bottom: ${Margins.Small};
`;

const PreviousVisitsTitle = styled(H1)`
  margin-top: ${Margins.Double};
  margin-bottom: ${Margins.Medium};
`;
const SpaceDivider = styled(Divider)`
  margin-bottom: 5px;
`;

const CaseListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: -${Margins.Double};
`;

const FilterWrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  margin-left: 35px;
  margin-bottom: 22px;
`;

interface Props {
  caseId: number;
}

const RepairShopComponent: React.FC<Props> = ({ caseId }) => {
  const repairShopInformation = useRef<ZebraListProps>(
    initialRepairShopInformation
  );

  const { loading, setLoading, getCases } = useContext(CasesContext);

  const [data, setData] = useState<GetRepairShopDetailsResponse | undefined>(
    undefined
  );

  useEffect(() => {
    setLoading(true);

    const getRepairShopDetails = async (controller: AbortController) => {
      const client = new CasesClient();
      client
        .getRepairShopDetails(caseId, controller.signal)
        .then((response) => {
          setData(response);
        })
        .catch((error) => console.log(error));
    };

    const controller = new AbortController();

    getRepairShopDetails(controller);
    getCases(caseId);

    return () => {
      controller.abort();
      setData(undefined);
      repairShopInformation.current = initialRepairShopInformation;
    };
  }, [caseId]);

  useEffect(() => {
    processRepairShopDetailsResponse(data);
  }, [data]);

  const processRepairShopDetailsResponse = (
    response: GetRepairShopDetailsResponse | undefined
  ) => {
    if (response) {
      repairShopInformation.current.list[0].value = response.city ?? '';
      repairShopInformation.current.list[1].value =
        response.streetAddress ?? '';
      repairShopInformation.current.list[2].value = response.zipCode ?? '';

      if (response.phoneNumbers) {
        const existsAlready = repairShopInformation.current.list.filter(
          (f) => f.name === 'Telefonnummer'
        );
        if (existsAlready === undefined || existsAlready.length === 0) {
          response.phoneNumbers.forEach((phone) => {
            repairShopInformation.current.list.push({
              name: 'Telefonnummer',
              value: phone,
            });
          });
        }
      }
    }
  };

  return (
    <RepairShopContainer>
      {data === undefined && <Loader center={true} />}
      {data && (
        <>
          <SubContainer>
            <Title>{!!data?.name ? data?.name : 'Ej lämnad på verkstad'}</Title>
            <SpaceDivider />
            <ZebraList
              title={repairShopInformation.current.title}
              list={repairShopInformation.current.list}
              firstColumnWidth={FirstColumnWidth.Default}
            />
          </SubContainer>

          {data.name !== undefined && (
            <PreviousVisitsTitle>
              Tidigare körningar till verkstaden
            </PreviousVisitsTitle>
          )}
          {data.name !== undefined && (
            <CaseListWrapper>
              <FilterWrapper>
                <CaseFilterComponent />
                <PaginationComponent />
              </FilterWrapper>
              {!loading && <CaseListComponent />}
              {loading && <Loader center={true} />}
              <Spacer />
              {!loading && <PaginationComponent />}
            </CaseListWrapper>
          )}
        </>
      )}
    </RepairShopContainer>
  );
};

export default RepairShopComponent;
