import React from 'react';
import styled from 'styled-components';
import { NavMenu } from './NavMenu';

const Wrapper = styled.div`
  padding-bottom: 30px;
`;

const Layout: React.FC = ({ children }) => {
  return (
    <Wrapper>
      <NavMenu />
      <div>{children}</div>
    </Wrapper>
  );
};

export default Layout;
