import React, { FunctionComponent, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FontFamily } from '../../utils/styles';

interface ModalProps {
  showModal: boolean;
}

const Modal = styled.div.attrs((props) => ({
  id: props.id,
}))<ModalProps>`
  position: fixed;
  display: ${(props) => (props.showModal ? 'flex' : 'none')};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  outline: 0;
  z-index: 1050;
  background-color: rgba(0, 0, 0, 0.3);
  text-align: center;
  box-sizing: border-box;
`;

const ModalDialog = styled.div`
  position: relative;
  display: inline-block;
  width: auto;
  margin: 30px auto;
  box-sizing: inherit;
`;

const ModalContent = styled.div`
  position: relative;
  box-sizing: inherit;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  box-shadow: 0 0 3px rgb(0 0 0 / 10%);
`;

const ModalBody = styled.div`
  box-sizing: inherit;
  position: relative;
  padding: 25px 20px 20px 20px;
`;

const ModalFooter = styled.div`
  box-sizing: inherit;
  padding: 0px 20px 20px;
  text-align: right;
`;

const FooterCloseButton = styled.button`
  box-sizing: inherit;
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 4px;
`;

interface ModalButton {
  /** if left undefined it defaults to closing the modal */
  onClick?(): void;
}

interface Props {
  buttons?: ModalButton[];
  className?: string;
  showModal: boolean;
  closeModal?: () => void;
}

const ImageModal: FunctionComponent<Props> = ({
  children,
  className,
  showModal,
  closeModal,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      // TODO: This triggers even if modal is not showing.
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        if (closeModal) {
          closeModal();
        } else {
          console.log('No closeModal callback registered!');
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closeModal, modalRef]);

  return (
    <Modal
      id={'modal'}
      className={'fade'}
      tabIndex={-1}
      role={'dialog'}
      showModal={showModal}
      aria-hidden={showModal ? 'true' : 'false'}
    >
      <ModalDialog ref={modalRef}>
        <ModalContent>
          <ModalBody>{children}</ModalBody>
          <ModalFooter>
            <FooterCloseButton
              type={'button'}
              data-dismiss={'modal'}
              onClick={() => closeModal && closeModal()}
            >
              Stäng
            </FooterCloseButton>
          </ModalFooter>
        </ModalContent>
      </ModalDialog>
    </Modal>
  );
};

export default ImageModal;
