import { ZebraListItem } from '../../ZebraList/ZebraList';

export const initialDrivePaths = () => {
  return {
    title: '',
    columns: 3,
    showListIndex: true,
    list: [
      { name: '', value: '', flex: 4, title: 'Adress' },
      { name: '', value: '', flex: 2, title: 'Destinationstyp' },
      { name: '', value: '', flex: 2, title: 'Ankomsttid', alignRight: true },
    ],
  };
};

export const initialPriceBaseValues = () => {
  return {
    title: 'Grundvärden',
    columns: 2,
    list: [
      { name: 'Enhetstaxa', value: '', flex: 3 },
      { name: "A'pris extrakörning", value: '', flex: 2 },
      { name: 'Kilometertillägg', value: '', flex: 3 },
    ],
  };
};

export const initialDrivenPaths = () => {
  return {
    title: 'Körda sträckor',
    columns: 2,
    list: [
      { name: 'Total körsträcka', value: '', flex: 3 },
      { name: 'Extrakörning', value: '', flex: 2 },
    ],
  };
};

export const emptyZebraListItem = (
  numberOfColumns: number,
  flexes: number[]
): ZebraListItem[] => {
  const arr: ZebraListItem[] = [];

  for (let i = 0; i < numberOfColumns; i++) {
    arr.push({ name: '', value: '', flex: flexes[i] ?? 1 });
  }

  return arr;
};

export const initialPrice = () => {
  return {
    title: 'Pris',
    columns: 5,
    list: [
      { name: '', value: '', flex: 4, title: 'Specifikation' },
      { name: '', value: '', flex: 1, title: 'Antal' },
      { name: '', value: '', flex: 2, title: 'Enhet' },
      { name: '', value: '', flex: 2, title: 'a-pris' },
      { name: '', value: '', flex: 2, title: 'Summa(kr)', alignRight: true },
      { name: '', value: 'Enhetstaxa', flex: 4 },
      { name: '', value: '', flex: 1 },
      { name: '', value: '', flex: 2 },
      { name: '', value: '', flex: 2 },
      { name: '', value: '', flex: 2, alignRight: true },
      { name: '', value: '', flex: 4 },
      { name: '', value: '', flex: 1 },
      { name: '', value: '', flex: 2 },
      { name: '', value: '', flex: 2 },
      { name: '', value: '', flex: 2, alignRight: true },
      { name: '', value: 'Delsumma 1', flex: 4 },
      { name: '', value: '', flex: 1 },
      { name: '', value: '', flex: 2 },
      { name: '', value: '', flex: 2 },
      { name: '', value: '', flex: 2, alignRight: true },
      { name: '', value: 'Moms 25%', flex: 4 },
      { name: '', value: '', flex: 1 },
      { name: '', value: '', flex: 2 },
      { name: '', value: '', flex: 2 },
      { name: '', value: '', flex: 2, alignRight: true },
      { name: '', value: 'Total', flex: 4 },
      { name: '', value: '', flex: 1 },
      { name: '', value: '', flex: 2 },
      { name: '', value: '', flex: 2 },
      { name: '', value: '', flex: 2, alignRight: true },
      { name: '', value: '', flex: 4 },
      { name: '', value: '', flex: 1 },
      { name: '', value: '', flex: 2 },
      { name: '', value: '', flex: 2 },
      { name: '', value: '', flex: 2, alignRight: true },
      { name: '', value: 'Självrisk', flex: 4 },
      { name: '', value: '', flex: 1 },
      { name: '', value: '', flex: 2 },
      { name: '', value: '', flex: 2 },
      { name: '', value: '', flex: 2, alignRight: true },
    ],
  };
};
