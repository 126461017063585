import styled from 'styled-components';
import { FontFamily, FontSize } from '../../utils/styles';

export const H1 = styled.h1`
  font-size: ${FontSize.H1};
`;

export const H2 = styled.h2`
  font-size: ${FontSize.H2};
  font-weight: bold;
`;

export const H3 = styled.h3`
  font-size: ${FontSize.H3};
  font-weight: bold;
`;
