import Loader from '../Loader';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { useGoogleMapsApi } from './GoogleMapsApiContext';
import { useGoogleMap } from './hooks/useGoogleMap';
import { useMapMarkers } from './hooks/useMapMarkers';
import { InfoWindowContent } from './utils/GoogleMapsUtils';

interface Props {
  markers: {
    [key: string]: {
      marker: google.maps.Marker;
      info?: InfoWindowContent;
    };
  };
  center: google.maps.LatLng | google.maps.LatLngLiteral;
  zoom?: number;
  useZoomControl?: boolean;
  scrollwheel?: boolean;
  addIndex?: number;
  setMarkerClickFunction?: (fn: (id: string) => void) => void;
}

const MapContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const Map: React.FC<Props> = ({
  markers,
  center,
  zoom,
  useZoomControl,
  scrollwheel,
  addIndex,
  setMarkerClickFunction,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { scriptLoaded } = useGoogleMapsApi();
  const { map, infoWindow } = useGoogleMap({
    mapRef: ref,
    zoom: zoom ?? 16,
    useZoomControl,
    center: center,
    scrollwheel: scrollwheel ?? false,
  });

  const { onClickFromOutside } = useMapMarkers({
    map: map,
    markers: markers ?? {},
    infoWindow: infoWindow,
    addIndex: addIndex,
  });

  if (!!setMarkerClickFunction) {
    setMarkerClickFunction((index: string) => {
      onClickFromOutside(index);
    });
  }

  return (
    <>
      {!scriptLoaded && <Loader />}
      {scriptLoaded && <MapContainer ref={ref} />}
    </>
  );
};

export default Map;
