import { useEffect, useState } from 'react';
import {
  centerInfoWindow,
  InfoWindowContent,
  RenderContent,
} from '../utils/GoogleMapsUtils';

interface MarkerProps {
  map: google.maps.Map | undefined;
  markers: {
    [key: string]: {
      marker: google.maps.Marker;
      info?: InfoWindowContent;
    };
  };
  infoWindow?: google.maps.InfoWindow;
  addIndex?: number;
}

export interface ExtendedMarker extends google.maps.Marker {
  info?: InfoWindowContent;
  id: string;
  posIndex?: number;
  listener?: google.maps.MapsEventListener;
}

export const useMapMarkers = ({
  map,
  markers,
  infoWindow,
  addIndex,
}: MarkerProps) => {
  const [mapMarkers, setMapMarkers] = useState<ExtendedMarker[]>([]);
  const [indexedMarker, setIndexedMarker] = useState<ExtendedMarker | null>(
    null
  );

  useEffect(() => {
    const unloadMarkers = () => {
      mapMarkers.forEach((marker) => {
        marker.setMap(null);
        google.maps.event.clearInstanceListeners(marker);
      });
    };

    return () => {
      unloadMarkers();
      setMapMarkers([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    mapMarkers.forEach((mark) => {
      mark.addListener('click', () => {
        onClick(mark, false);
      });
    });
    return () => {
      mapMarkers.forEach((mark) => {
        mark.listener?.remove();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapMarkers, indexedMarker]);

  useEffect(() => {
    if (map && mapMarkers.length === 0) {
      const posList = Object.keys(markers)
        .reduce((prev, curr) => {
          const tempMarker = markers[curr].marker as ExtendedMarker;
          const lat = tempMarker.getPosition()?.lat();
          if (lat === undefined) {
            return prev;
          } else {
            return [...prev, lat];
          }
        }, [] as number[])
        .sort((a, b) => b - a);

      var markerList = Object.keys(markers).map((f, index) => {
        var mark = markers[f].marker as ExtendedMarker;
        if (markers[f].info) {
          mark.info = markers[f].info;
          mark.info!.index = index + 1 + (addIndex ?? 0);
        }
        mark.setMap(map);

        let pos = mark.getPosition();
        if (pos !== undefined && pos !== null) {
          mark.posIndex = posList.indexOf(mark.getPosition()!.lat()) + 10;
        } else {
          mark.posIndex = 10;
        }

        mark.setZIndex(mark.posIndex!);
        mark.id = f;

        return mark;
      });

      /*
        With more than one marker, try to fit all of them within the maps bounds
        else just center the map around the one marker.
      */
      if (markerList.length > 1) {
        let bounds = new google.maps.LatLngBounds();
        for (let i = 0; i < markerList.length; i++) {
          bounds.extend(markerList[i].getPosition()!);
        }
        map.fitBounds(bounds);
      } else {
        map.setCenter(markerList[0].getPosition()!);
      }

      setMapMarkers(markerList);
    }
  }, [addIndex, map, mapMarkers, markers]);

  var openInnerInfoWindow = (marker: ExtendedMarker) => {
    if (map && infoWindow && marker.info) {
      infoWindow!.close();
      infoWindow!.setContent(RenderContent(marker.info!).toString());
      infoWindow!.setPosition(centerInfoWindow(map, marker.getPosition()!));
      infoWindow!.open(map);
    }
  };

  const onClickFromOutside = (id: string) => {
    var marker = mapMarkers.find((f) => f.id === id);
    if (marker !== undefined) {
      onClick(marker, true);
    }
  };

  const onClick = (marker: ExtendedMarker, center: boolean) => {
    if (indexedMarker) {
      indexedMarker.setZIndex(indexedMarker.posIndex!);
    }
    if (center) {
      map?.setCenter(marker.getPosition()!);
    }
    marker.setZIndex(181);

    setIndexedMarker(marker);
    openInnerInfoWindow(marker!);
  };

  return { mapMarkers, setMapMarkers, onClickFromOutside };
};
