import React, { useContext, useEffect, useRef } from 'react';
import { BsSearch } from 'react-icons/bs';
import styled from 'styled-components';
import { Colors, FontFamily, FontSize } from '../../utils/styles';
import DatePicker from 'react-datepicker';
import { CasesContext } from './CasesContext';

// interface Props {
//   setSearchInput: (input: string) => void;
//   setTimespan: (timespan: SearchDropdownOptionType) => void;
// }

const SearchBox = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
  height: 29px;
  border: 1px solid ${Colors.LightGrey};
`;

const ComponentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const SearchInput = styled.input`
  display: inline-flex;
  font-size: ${FontSize.ZebraTitle};
  border: 0;
  padding: 5px 0 5px 10px;
  &:focus {
    outline: none;
  }
`;

const SelectContainer = styled.div`
  display: inline-flex;
  position: relative;
  border: 1px solid ${Colors.LightGrey};
  height: 29px;
  width: 137px;
  /* margin-bottom: 6px; */
  margin-right: 20px;
`;

const CustomInput = (
  props: React.HTMLProps<HTMLInputElement>,
  ref: React.Ref<HTMLInputElement>
) => (
  <input
    style={{
      border: 0,
      backgroundColor: 'transparent',
      outline: 'none',
      width: '127px',
      height: '29px',
      fontSize: `${FontSize.ZebraTitle}`,
      zIndex: 0,
      cursor: 'pointer',
    }}
    {...props}
  ></input>
);

const SearchComponent: React.FC = () => {
  const {
    loading,
    getFrom,
    getTo,
    setFrom,
    setTo,
    getSearchInput,
    setSearchInput,
  } = useContext(CasesContext);

  const searchInputTimeout = useRef<NodeJS.Timeout | undefined>(undefined);

  const changeSearchInput = (newSearchInput: string) => {
    if (searchInputTimeout.current) {
      clearTimeout(searchInputTimeout.current!);
    }
    if (getSearchInput() !== newSearchInput) {
      searchInputTimeout.current = setTimeout(() => {
        setSearchInput(newSearchInput);
      }, 400);
    }
  };

  useEffect(() => {
    return () => {
      if (searchInputTimeout.current !== undefined) {
        clearTimeout(searchInputTimeout.current);
      }
    };
  }, []);

  return (
    <ComponentWrapper>
      <SearchBox>
        <SearchInput
          spellCheck='false'
          placeholder='Sök'
          onChange={(e) => changeSearchInput(e.currentTarget.value)}
        />
        <BsSearch
          style={{
            verticalAlign: 'sub',
            fontSize: '12px',
            marginRight: '5px',
            color: Colors.NearBlack,
          }}
        />
      </SearchBox>
      <SelectContainer>
        <DatePicker
          maxDate={new Date(Date.now())}
          disabled={loading ? true : false}
          selected={getFrom()}
          onChange={(date: Date) => setFrom(date)}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode='select'
          customInput={React.createElement(React.forwardRef(CustomInput))}
        />
      </SelectContainer>
      <SelectContainer>
        <DatePicker
          maxDate={new Date(Date.now())}
          disabled={loading ? true : false}
          selected={getTo()}
          onChange={(date: Date) => setTo(date)}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode='select'
          customInput={React.createElement(React.forwardRef(CustomInput))}
        />
      </SelectContainer>
    </ComponentWrapper>
  );
};

export default SearchComponent;
