import React, { FunctionComponent, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { FontFamily } from '../../utils/styles';
import useModalStack from './useModalStack';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 98;

  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(255, 255, 255, 0.7);
`;

const TheModal = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 400px;
  min-height: 200px;
  max-width: 95vw;
  max-height: 95vh;
  overflow: hidden;
  z-index: 99;
`;
/* background: ${Color.background.mainBackground};
  box-shadow: ${Color.boxShadow.main.down}; */

const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 20px;
  padding-top: 20px;
  font-weight: 600;
`;
/* font-size: ${Size.font.large}; */
/* color: ${Color.foreground.mainBackground}; */

/* font-size: ${Size.font.small}; */
const ChildWrap = styled.div<{ noPadding?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;

  ${({ noPadding }) =>
    !noPadding
      ? css`
          padding: 10px 20px;
        `
      : css`
          padding: 0;
        `}
`;

const ButtonsWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  padding-right: 10px;
`;

const MyButton = styled.button`
  margin-right: 10px;
`;

export interface ModalButton {
  label: React.ReactChild;
  /** if left undefined it defaults to closing the modal */
  onClick?(): void;
  dontPopAfterClick?: boolean;
  disabled?: boolean;
}

interface Props {
  buttons?: ModalButton[];
  title?: string;
  /** prevent padding on child container */
  noPadding?: boolean;
  className?: string;
  closeModal?: () => void;
}

const Modal: FunctionComponent<Props> = ({
  children,
  buttons,
  title,
  noPadding,
  className,
  closeModal,
}) => {
  const modalStack = useModalStack();
  const modalRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: any) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      if (closeModal) {
        closeModal();
      } else {
        console.log('No closeModal callback registered!');
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modalRef]);

  return (
    <Overlay>
      <TheModal ref={modalRef}>
        {title && <Title>{title}</Title>}

        <ChildWrap noPadding={noPadding} className={className}>
          {children}
        </ChildWrap>

        {buttons && (
          <ButtonsWrap>
            {buttons.map((button, index) => (
              <MyButton
                key={index}
                onClick={() => {
                  button.onClick && button.onClick();
                  if (!button.dontPopAfterClick) {
                    modalStack.pop();
                  }
                }}
                disabled={button.disabled}
              >
                {button.label}
              </MyButton>
            ))}
          </ButtonsWrap>
        )}
      </TheModal>
    </Overlay>
  );
};

export default Modal;
