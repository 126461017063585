import React from 'react';
import styled, { css } from 'styled-components';
import { Colors } from '../../../utils/styles';
import { CaseDTO } from '../../api-clients';

const Wrapper = styled.div`
  text-align: center;
  margin-bottom: 20px;
  max-width: 400px;
`;

const Header = styled.div``;

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
`;

const Button = styled.div<{ active: boolean }>`
  flex: 1;
  padding: 4px;
  max-width: 29%;
  border: 1px solid ${Colors.LightGrey};
  cursor: pointer;
  border-radius: 3px;
  ${(props) =>
    props.active &&
    css`
      background: #e39704;
      color: white;
      font-weight: bold;
    `}
`;

interface Props {
  updateInvestigationCheck: (checkValue: boolean | undefined) => void;
  shellCase: CaseDTO;
}
const InvestigationCheck: React.FC<Props> = ({
  updateInvestigationCheck,
  shellCase,
}) => {
  var buttonList = [
    {
      title: 'Ja!',
      value: true,
    },
    {
      title: 'Inget valt',
      value: undefined,
    },
    { title: 'Nej.', value: false },
  ];
  return (
    <Wrapper>
      <Header>Är ärendet intressant för utredning?</Header>
      <ButtonWrapper>
        {buttonList.map((f) => (
          <Button
            active={f.value == shellCase.isInvestigationCase}
            onClick={() => {
              updateInvestigationCheck(f.value);
            }}
          >
            {f.title}
          </Button>
        ))}
      </ButtonWrapper>
    </Wrapper>
  );
};

export default InvestigationCheck;
