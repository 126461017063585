import React, { useEffect, useState } from 'react';
import { GlobalFonts } from './fonts/fonts';
import CasesProvider from './components/Cases/CasesContext';
import Routes from './Routes';
import IncidentsMapProvider from './components/IncidentsMap/IncidentsMapContext';
import GoogleMapsApiProvider from './components/GoogleMaps/GoogleMapsApiContext';
import GoogleMapsProvider from './components/GoogleMaps/GoogleMapsContext';
import BasicDataProvider from './utils/useBasicData';
import { ModalProvider } from './components/Modal/useModalStack';

// console.log = () => {};

const App: React.FC = () => {
  return (
    <>
      <GlobalFonts />
      <BasicDataProvider>
        <ModalProvider>
          <CasesProvider>
            <GoogleMapsApiProvider>
              <GoogleMapsProvider>
                <IncidentsMapProvider>
                  <Routes />
                </IncidentsMapProvider>
              </GoogleMapsProvider>
            </GoogleMapsApiProvider>
          </CasesProvider>
        </ModalProvider>
      </BasicDataProvider>
    </>
  );
};

export default App;
