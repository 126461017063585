import React, { useContext } from 'react';
import { GoChevronRight, GoChevronLeft } from 'react-icons/go';
import styled from 'styled-components';
import { Colors, FontFamily } from '../../utils/styles';
import { CasesContext } from './CasesContext';

const Span = styled.span`
  font-weight: normal;
`;

const Container = styled.div`
  display: inline-flex;
  margin-left: auto;
  margin-right: 35px;
`;

const PaginationComponent: React.FC = () => {
  const {
    getPageIndex,
    getPageSize,
    filteredNumber,
    setPageIndex,
  } = useContext(CasesContext);

  return (
    <Container>
      <Span>{getPageIndex() * getPageSize() + 1} -&nbsp;</Span>
      {(getPageIndex() + 1) * getPageSize() > filteredNumber ? (
        <Span>{filteredNumber}</Span>
      ) : (
        <Span>{(getPageIndex() + 1) * getPageSize()}</Span>
      )}
      <Span>&nbsp;av {filteredNumber}</Span>
      {getPageIndex() > 0 ? (
        <GoChevronLeft
          style={{ color: Colors.Black, cursor: 'pointer' }}
          onClick={() => {
            setPageIndex(getPageIndex() - 1);
          }}
        />
      ) : (
        <GoChevronLeft style={{ color: Colors.LightGrey, cursor: 'default' }} />
      )}
      {(getPageIndex() + 1) * getPageSize() < filteredNumber ? (
        <GoChevronRight
          style={{ color: Colors.Black, cursor: 'pointer' }}
          onClick={() => {
            setPageIndex(getPageIndex() + 1);
          }}
        />
      ) : (
        <GoChevronRight
          style={{ color: Colors.LightGrey, cursor: 'default' }}
        />
      )}
    </Container>
  );
};

export default PaginationComponent;
