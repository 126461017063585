import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import Modal from '.';

interface ModalStack {
  readonly stack: React.ReactChild[];
  push: (child: React.ReactChild) => void;
  pop: () => void;
}

export const ModalStackContext = React.createContext<ModalStack>({
  stack: [],
  push: () => {},
  pop: () => {},
});

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalProvider: React.FunctionComponent = ({ children }) => {
  const [stack, setStack] = useState<React.ReactChild[]>([]);

  const push = (component: React.ReactChild) => {
    setStack((stack) => [...stack, component]);
  };

  const pop = () => {
    setStack((stack) => {
      const newStack = [...stack];
      newStack.pop();
      return newStack;
    });
  };

  return (
    <ModalStackContext.Provider value={{ stack, push, pop }}>
      {stack.length > 0 && stack[stack.length - 1] && (
        <Modal closeModal={pop}>{stack[0]}</Modal>
      )}
      {children}
    </ModalStackContext.Provider>
  );
};

const useModalStack = () => {
  const context = useContext(ModalStackContext);
  return context;
};

export default useModalStack;
