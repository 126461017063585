import Loader from '../Loader';
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { FontFamily } from '../../utils/styles';
import CaseListComponent from './CaseListComponent';
import { CasesContext } from './CasesContext';
import CaseFilterComponent from './CaseFilterComponent';
import SearchComponent from './SearchComponent';
import PaginationComponent from './PaginationComponent';
import { Spacer } from './styles';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  padding-top: 40px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled.div``;

const H1 = styled.h1`
  font-weight: normal;
  font-size: 3em;
  margin-left: 35px;
  margin-right: 20px;
  margin-bottom: 0px;
  margin-top: 0px;
`;

const FilterWrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  margin-left: 35px;
  margin-bottom: 22px;
`;

const CaseContainer: React.FC = () => {
  return (
    <Container>
      <Row>
        <H1>ÄRENDEN</H1>
        <SearchComponent />
      </Row>
      <Column>
        <FilterWrapper>
          <CaseFilterComponent />
          <PaginationComponent />
        </FilterWrapper>
        <CaseListComponent />
        <Spacer />
        <PaginationComponent />
      </Column>
    </Container>
  );
};

export default CaseContainer;
