import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { Colors, FontFamily } from '../../utils/styles';
import { BsStar, BsStarFill, BsFlagFill } from 'react-icons/bs';
import { caseComponentDefinition } from './case-definition';
import {
  CaseDTO,
  CasesClient,
  FlagEnum,
  ToggleUserFlagRequest,
} from '../api-clients/index';
import {
  CaseComponentColumnType,
  MED_WAYSTARSCORE,
  ICaseComponentColumnDefinition,
  HIGH_WAYSTARSCORE,
  LOW_WAYSTARSCORE,
} from './types';
import { CasesContext } from './CasesContext';
import dayjs from 'dayjs';
import { BasicDataContext, UserRole } from '../../utils/useBasicData';

const Container = styled.div<{ setBackground: boolean }>`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  background-color: ${(props) =>
    props.setBackground ? Colors.WhiteShade : ''};
  user-select: none;
  &:hover {
    background-color: ${Colors.Hover};
  }
`;

const Div = styled.div<{
  flex: number;
  bold?: boolean;
  textAlign?: string;
  override: boolean;
}>`
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  flex: ${(props) => props.flex};
  white-space: nowrap;
  width: 100%;
  height: 30px;
  line-height: 30px;

  svg {
    margin-right: -3px;
  }

  ${({ override }) =>
    override &&
    css`
      overflow: visible;
    `}
`;

const ImgContainer = styled.div<{
  src: string;
}>`
  display: inline-flex;
  min-width: min-content;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  margin-bottom: -5px;
  height: 30px;
  width: 42px;
  background-image: ${(props) => `url(${props.src})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
`;

interface Props {
  _case: CaseDTO;
  setBackground: boolean;
  openWindow: () => void;
}

const CaseComponent: React.FC<Props> = ({
  _case,
  setBackground,
  openWindow,
}) => {
  const { update } = useContext(CasesContext);
  const { user } = useContext(BasicDataContext);

  const systemAdmin = !!user?.roles?.find((f) => f == UserRole.SystemAdmin);
  const isUnread =
    _case.userFlags!.find((uf) => uf.flagId === FlagEnum.Unread) !== undefined;
  let isFavorite =
    _case.userFlags!.find((uf) => uf.flagId === FlagEnum.Starred) !== undefined;
  const score = _case.waystarScore;
  const handleClick = (type: FlagEnum) => {
    if (_case.id) {
      update(_case.id, type);
      toggleUserFlag(type);
    }
  };

  const toggleUserFlag = async (type: FlagEnum) => {
    const client = new CasesClient();
    await client.toggleUserFlag(
      new ToggleUserFlagRequest({
        flagType: type,
        caseId: _case.id,
      })
    );
  };

  const generateContentType = (
    _case: CaseDTO,
    cd: ICaseComponentColumnDefinition
  ) => {
    switch (cd.type) {
      case CaseComponentColumnType.SPAN:
        return cd.property
          ? _case[cd.property as keyof CaseDTO]
            ? _case[cd.property as keyof CaseDTO]
            : '-'
          : '';

      case CaseComponentColumnType.STAR:
        return isFavorite ? (
          <BsStarFill
            color={Colors.Golden}
            onClick={() => handleClick(FlagEnum.Starred)}
          />
        ) : (
          <BsStar
            color={Colors.DarkGrey}
            onClick={() => handleClick(FlagEnum.Starred)}
          />
        );
      case CaseComponentColumnType.SCORE:
        var prop: any = _case.waystarScore;

        if (systemAdmin && _case.internalWaystarScore != null) {
          var internalScore: any = _case.internalWaystarScore;
          if (!isNaN(prop) && !isNaN(internalScore)) {
            return `${parseFloat(prop).toFixed(2)} (${parseFloat(
              internalScore
            ).toFixed(2)})`;
          }
        }
        if (!isNaN(prop)) {
          return parseFloat(prop).toFixed(2);
        }
        break;
      case CaseComponentColumnType.NUM_2:
        var prop: any = cd.property
          ? _case[cd.property as keyof CaseDTO]
            ? _case[cd.property as keyof CaseDTO]
            : '-'
          : '';

        if (!isNaN(prop)) {
          return parseFloat(prop).toFixed(2);
        }
        break;
      case CaseComponentColumnType.FLAG:
        var scores = [];
        if (score) {
          if (score > LOW_WAYSTARSCORE) {
            scores.push(<BsFlagFill color={Colors.Red} key={1} />);
          }
          if (score > MED_WAYSTARSCORE) {
            scores.push(<BsFlagFill color={Colors.Red} key={2} />);
          }
          if (score > HIGH_WAYSTARSCORE) {
            scores.push(<BsFlagFill color={Colors.Red} key={3} />);
          }
        }
        return scores;

      case CaseComponentColumnType.IMAGE: {
        const src =
          _case.imageIds && _case.imageIds.length > 0
            ? `/Case/Thumbnail/${_case.imageIds[0]}`
            : '';
        return <ImgContainer src={src} />;
      }

      case CaseComponentColumnType.DATE:
        return dayjs(_case[cd.property as keyof CaseDTO] as Date).format(
          'YYYY-MM-DD HH:mm:ss'
        );
    }
  };

  var defintions = caseComponentDefinition;
  if (systemAdmin) {
    defintions = defintions.map((f) => {
      if (f.type === CaseComponentColumnType.FLAG) {
        f.flex = 1.5;
      }
      if (f.type === CaseComponentColumnType.SCORE) {
        f.flex = 3.5;
      }
      return f;
    });
  }

  return (
    <Container setBackground={setBackground}>
      {defintions.map((cd, index) => (
        <Div
          override={systemAdmin && cd.type === CaseComponentColumnType.SCORE}
          style={cd.style}
          key={index}
          bold={isUnread}
          flex={cd.flex}
          onClick={
            cd.type !== CaseComponentColumnType.STAR
              ? () => openWindow()
              : undefined
          }
        >
          {generateContentType(_case, cd)}
        </Div>
      ))}
    </Container>
  );
};

export default CaseComponent;
