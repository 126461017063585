import React, { useContext } from 'react';
import styled from 'styled-components';
import { Colors, FontFamily } from '../../utils/styles';
import CaseComponent from './CaseComponent';
import { BsStarFill } from 'react-icons/bs';
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';
import { CasesContext } from './CasesContext';
import { useHistory } from 'react-router';
import { CasesOrderBy, CasesSort } from '../api-clients';
import Loader from '../Loader';
import { Spacer } from './styles';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
`;

const Column = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
`;

const Title = styled.span<{
  flex: number;
  textAlign?: string;
  clickable?: boolean;
}>`
  font-weight: bold;
  flex: ${(props) => props.flex};
  user-select: none;
  min-width: min-content;
  white-space: nowrap;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
  cursor: ${(props) => (props.clickable ? 'pointer' : '')};
`;

const Border = styled.div`
  border-bottom: 1px solid ${Colors.LightGrey};
`;

const Overlay = styled.div`
  position: absolute;
  z-index: 10;
  height: 100vh;
  width: 99vw;
  background-color: rgb(255, 255, 255, 0.4);
`;

const CaseListComponent: React.FC = () => {
  const {
    loading,
    setLoading,
    cases,
    getCurrentSort,
    setCurrentSort,
    getCurrentOrderBy,
    setCurrentOrderBy,
    saveSettings,
    setDefaultSettings,
  } = useContext(CasesContext);
  const history = useHistory();

  const gotoCaseDetails = (id: number) => {
    console.log('gotoCaseDetails');
    saveSettings();
    // setDefaultSettings();
    history.push(`/case/${id}`);
  };

  let mapDate = 0;

  return (
    <Column>
      <Overlay hidden={!loading ? true : false}>
        <Spacer />
        <Spacer />
        <Loader />
      </Overlay>
      <Row>
        <Title flex={1}></Title>
        <Title flex={1}>
          <BsStarFill color='darkgrey' />
        </Title>
        <Title
          flex={5}
          clickable={true}
          onClick={() => {
            if (getCurrentOrderBy() !== CasesOrderBy.WAYSTARSCORE) {
              setCurrentOrderBy(CasesOrderBy.WAYSTARSCORE);
            }
            if (getCurrentSort() === CasesSort.DESCENDING) {
              setCurrentSort(CasesSort.ASCENDING);
            } else {
              setCurrentSort(CasesSort.DESCENDING);
            }
            setLoading(true);
          }}
        >
          WayStar Score
          {getCurrentOrderBy() === CasesOrderBy.WAYSTARSCORE ? (
            getCurrentSort() === CasesSort.DESCENDING ? (
              <RiArrowDownSLine
                style={{
                  verticalAlign: 'middle',
                  marginLeft: '2px',
                  marginBottom: '2px',
                  cursor: 'pointer',
                }}
              />
            ) : (
              <RiArrowUpSLine
                style={{
                  verticalAlign: 'middle',
                  marginLeft: '2px',
                  marginBottom: '2px',
                  cursor: 'pointer',
                }}
              />
            )
          ) : null}
        </Title>
        <Title flex={3}>Bilder</Title>
        <Title flex={4}>Reg.Nr</Title>
        <Title flex={10}>Fordon</Title>
        <Title flex={12}>Adress</Title>
        <Title flex={9}>Skadetyp</Title>
        <Title
          flex={7}
          clickable={true}
          textAlign={'right'}
          onClick={() => {
            if (getCurrentOrderBy() !== CasesOrderBy.CREATED) {
              setCurrentOrderBy(CasesOrderBy.CREATED);
            }
            if (getCurrentSort() === CasesSort.DESCENDING) {
              setCurrentSort(CasesSort.ASCENDING);
            } else {
              setCurrentSort(CasesSort.DESCENDING);
            }
            setLoading(true);
          }}
        >
          Skadedatum
          {getCurrentOrderBy() === CasesOrderBy.CREATED ? (
            getCurrentSort() === CasesSort.DESCENDING ? (
              <RiArrowDownSLine
                style={{
                  verticalAlign: 'middle',
                  marginLeft: '2px',
                  marginBottom: '2px',
                  cursor: 'pointer',
                }}
              />
            ) : (
              <RiArrowUpSLine
                style={{
                  verticalAlign: 'middle',
                  marginLeft: '2px',
                  marginBottom: '2px',
                  cursor: 'pointer',
                }}
              />
            )
          ) : null}
        </Title>
        <Title
          flex={7}
          clickable={true}
          textAlign={'right'}
          onClick={() => {
            if (getCurrentOrderBy() !== CasesOrderBy.DATETIME) {
              setCurrentOrderBy(CasesOrderBy.DATETIME);
            }
            if (getCurrentSort() === CasesSort.DESCENDING) {
              setCurrentSort(CasesSort.ASCENDING);
            } else {
              setCurrentSort(CasesSort.DESCENDING);
            }
            setLoading(true);
          }}
        >
          Inläst
          {getCurrentOrderBy() === CasesOrderBy.DATETIME ? (
            getCurrentSort() === CasesSort.DESCENDING ? (
              <RiArrowDownSLine
                style={{
                  verticalAlign: 'middle',
                  marginLeft: '2px',
                  marginBottom: '2px',
                  cursor: 'pointer',
                }}
              />
            ) : (
              <RiArrowUpSLine
                style={{
                  verticalAlign: 'middle',
                  marginLeft: '2px',
                  marginBottom: '2px',
                  cursor: 'pointer',
                }}
              />
            )
          ) : null}
        </Title>
        <Title flex={1}></Title>
      </Row>
      <Border />
      {cases.map((_case, index) => {
        const addBorder = _case.crashDate?.getDate() !== mapDate;
        mapDate = _case.crashDate!.getDate();

        return (
          <Column key={index}>
            {addBorder && index !== 0 && <Border />}
            <CaseComponent
              _case={_case}
              setBackground={index % 2 === 0 ? false : true}
              openWindow={() => gotoCaseDetails(_case.id!)}
            />
          </Column>
        );
      })}
      <Border />
    </Column>
  );
};

export default CaseListComponent;
