import { CaseDTO, CasesFilter, CasesOrderBy, CasesSort } from '../api-clients';

export enum SearchDropdownOptionType {
  LAST_YEAR = 'Senaste 12 mån',
  LAST_HALFYEAR = 'Senaste 6 mån',
  LAST_QUARTER = 'Senaste 3 mån',
  LAST_MONTH = 'Senaste månaden',
}

export enum CaseComponentColumnType {
  SPAN = 'SPAN',
  STAR = 'STAR',
  FLAG = 'FLAG',
  IMAGE = 'IMAGE',
  DATE = 'DATE',
  ENUM = 'ENUM',
  NUM_2 = 'NUM_2',
  SCORE = 'SCORE',
}

export enum CaseDetailedActiveTab {
  INVESTIGATION = 'UTREDNING',
  PROCESSING = 'HANDLÄGGNING',
  CUSTOMER_AND_VEHICLE = 'KUND & FORDON',
  WORKSHOP_INFO = 'VERKSTADSINFO',
}

export interface ICaseComponentColumnDefinition {
  type: CaseComponentColumnType;
  flex: number;
  style?: React.CSSProperties;
  property?: string;
  enum?: string;
}

export const INSIG_WAYSTARSCORE = 0;
export const LOW_WAYSTARSCORE = 0.25;
export const MED_WAYSTARSCORE = 0.5;
export const HIGH_WAYSTARSCORE = 0.75;

export interface CasesContextSettings {
  pageIndex: number;
  pageSize: number;
  filter: CasesFilter;
  sort: CasesSort;
  orderBy: CasesOrderBy;
  searchInput: string;
  from: Date;
  to: Date;
}

export interface CasesContextState {
  loading: boolean;
  cases: CaseDTO[];
  pageIndex: number;
  pageSize: number;
  filter: CasesFilter;
  sort: CasesSort;
  orderBy: CasesOrderBy;
  numberOfCases: number;
  numberOfFilteredCases: number;
  numberOfCasesWithHighWaystarScore: number;
  numberOfStarMarkedCases: number;
  numberOfUnreadCases: number;
  searchInput: string;
  fromInput: Date;
  toInput: Date;
  isInRepairShopId: number | undefined;
  settings: CasesContextSettings | undefined;
}

export const initialCasesContextState: CasesContextState = {
  loading: true,
  cases: [],
  pageIndex: 0,
  pageSize: 50,
  filter: CasesFilter.ALL,
  sort: CasesSort.DESCENDING,
  orderBy: CasesOrderBy.DATETIME,
  numberOfCases: 0,
  numberOfFilteredCases: 0,
  numberOfCasesWithHighWaystarScore: 0,
  numberOfStarMarkedCases: 0,
  numberOfUnreadCases: 0,
  searchInput: '',
  fromInput: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
  toInput: new Date(),
  isInRepairShopId: undefined,
  settings: undefined,
};
