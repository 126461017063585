import CaseContainer from './components/Cases';
import React, { useContext } from 'react';
import { Route, Switch } from 'react-router';
import Layout from './components/Layout';
import IncidentsContainer from './components/IncidentsMap';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import CaseDetailed from './components/Cases/CaseDetailed';
import CarfaxReport from './components/Cases/CarfaxReport';
import NotFound from './components/NotFound';
import { BasicDataContext, RouteContent } from './utils/useBasicData';

const Routes: React.FC = () => {
  const { user, routeContent } = useContext(BasicDataContext);

  console.log(user);

  console.log(routeContent);

  var routes: JSX.Element[] = [];
  if (!!routeContent.find((f) => f === RouteContent.ViewContent)) {
    routes = [
      ...routes,
      <Route exact path='/' component={CaseContainer} />,
      <Route exact path='/case/cfr/:id' component={CarfaxReport} />,
      <Route exact path='/case/:id' component={CaseDetailed} />,
      <Route exact path='/incidents' component={IncidentsContainer} />,
    ];
  }
  if (!!routeContent.find((f) => f === RouteContent.ApiContent)) {
    routes = [
      ...routes,
      <Route exact path='/case/card/:id' component={CaseDetailed} />,
    ];
  }

  return (
    <Layout>
      <Switch>
        {routes}
        <Route component={NotFound} />
      </Switch>
    </Layout>
  );
};

export default Routes;
