import React, { useContext, useEffect, useRef, useState } from 'react';
import { BsFlagFill } from 'react-icons/bs';
import styled from 'styled-components';
import {
  Colors,
  FirstColumnWidth,
  FontFamily,
  Margins,
} from '../../../utils/styles';
import { GoogleMapsContext } from '../../GoogleMaps/GoogleMapsContext';
import Map from '../../GoogleMaps/Map';
import { H1, H2 } from '../../styled';
import VehicleHistoryContainer from '../../VehicleHistory';
import {
  HIGH_WAYSTARSCORE,
  LOW_WAYSTARSCORE,
  MED_WAYSTARSCORE,
} from '../types';
import {
  CaseDTO,
  CasesClient,
  DrivePathPoint,
  GetInvestigationDetailsResponse,
} from '../../api-clients';
import ImageCarousel from '../../Carousel/Carousel';
import { getReportLocationMarkerSvg } from '../../GoogleMaps/utils/GoogleMapsUtils';
import TowDriverIcon from '../../../images/towingcar_round.png';
import { Container, Divider, SubContainer } from '../styles';
import ZebraList, { ZebraListProps } from '../../ZebraList/ZebraList';
import {
  initialCallerContactInformation,
  initialCarOwnerContactInformation,
  initialCarDriverContactInformation,
  initialRepairShopInformation,
  initialIncidentInfo,
  initialAssignmentDurationInformation,
  initialCarfaxInformation,
} from './emptyData';
import dayjs from 'dayjs';
import dayJsUtcModule from 'dayjs/plugin/utc';
import Loader from '../../Loader';
import NoLicense from '../../NoLicense';
import useModalStack from '../../Modal/useModalStack';
import InvestigationCheck from './InvestigationCheck';
dayjs.extend(dayJsUtcModule);

const InvestigationContainer = styled(Container)`
  width: calc(100% - ${Margins.ExtraLarge});
`;

const MediaSubContainer = styled.div`
  display: inline-flex;
  flex: 1;
  max-width: 60%;
`;

const InformationContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  column-gap: ${Margins.Medium};
  margin-top: ${Margins.Medium};
  width: 100%;
`;

const InformationColumn = styled.div`
  display: inline-flex;
  flex-direction: column;
  flex: 1;
  width: 50%;
`;

const MapContainer = styled(InformationColumn)`
  flex: 1 1;
  width: 50%;
  min-width: 500px;
  height: 590px;
`;

const ImageContainer = styled(InformationColumn)``;

const ImageMapRow = styled(InformationContainer)`
  /* flex-wrap: wrap; */
  margin: ${Margins.Medium} 0px;
`;

const WaystarScoreTitle = styled(H1)`
  margin-bottom: ${Margins.Small};
`;

const FlagContent = styled.div`
  font-size: 18px;
  display: inline-block;
  margin-left: 5px;
  svg {
    margin-right: -3px;
  }
`;
const InfoTitle = styled(H2)`
  margin-bottom: 5px;
`;

const Text = styled.span<{
  bold?: boolean;
  link?: boolean;
}>`
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  color: ${(props) => (props.link ? Colors.Red : Colors.Black)};
  cursor: ${(props) => (props.link ? 'pointer' : '')};
`;

const TowDriverComment = styled.div.attrs((props) => ({
  className: props.className,
}))`
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  max-width: 400px;
  min-height: 98px;
  padding: ${Margins.Medium};
  text-align: left;
  position: relative;
  background-color: #ebebeb;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  margin-bottom: ${Margins.Medium};

  &.speechbubble:before {
    content: '';
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: ${Margins.Small} solid transparent;
    border-right: ${Margins.Small} solid transparent;
    border-top: ${Margins.Small} solid #ebebeb;
    border-bottom: ${Margins.Small} solid transparent;
    left: 10%;
    bottom: -${Margins.Medium};
  }
`;

const TowDriverInformation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-grow: 1;
  column-gap: ${Margins.Medium};
  margin-bottom: ${Margins.Medium};
`;

const TowDriverImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 100px;
  object-fit: cover;
  overflow: hidden;
  align-self: center;
`;

const TowDriverContact = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-top: 25px;
  flex: 1;
  margin-bottom: ${Margins.Medium};
`;

const FlagRowContainer = styled.div<{ useDivider?: boolean }>`
  display: inline-flex;
  flex-direction: row;
  margin: 15px 0px;
`;

const FlagWrapper = styled.div`
  display: flex;
  padding-top: 2px;
  padding-left: 5px;
  padding-right: 10px;
`;

const Link = styled.a`
  color: black;
  text-decoration: underline;
  cursor: pointer;
`;

const FakeLink = styled.span`
  color: black;
  text-decoration: underline;
  cursor: pointer;
`;

interface Props {
  caseId: number;
  shellCase: CaseDTO;
  updateInvestigationCheck: (checkValue: boolean | undefined) => void;
}

const getAssignmentDuration = (endTime?: Date, startTime?: Date) => {
  if (!endTime || !startTime) return '';

  let ended = dayjs(endTime);
  let started = dayjs(startTime);
  let minutesItTook = ended.diff(started, 'm');
  return `${minutesItTook} min`;
};

type FlagProps = {
  label: string;
  useDivider?: boolean;
};

const FlagComponent = ({ label, useDivider }: FlagProps) => {
  return (
    <>
      <FlagRowContainer>
        <FlagWrapper>
          <BsFlagFill size={18} color={Colors.Red} />
        </FlagWrapper>
        <Text>{label}</Text>
      </FlagRowContainer>
      {useDivider && <Divider />}
    </>
  );
};

const InvestigationComponent: React.FC<Props> = ({
  caseId,
  shellCase,
  updateInvestigationCheck,
}) => {
  const { scriptLoaded } = useContext(GoogleMapsContext);

  const [data, setData] = useState<GetInvestigationDetailsResponse | undefined>(
    undefined
  );

  const [incidentLocation, setIncidentLocation] = useState<
    DrivePathPoint | undefined
  >(undefined);

  const waystarScore = useRef<number>(0);
  const registrationNumber = useRef<string>('');
  const carBrand = useRef<string>('');
  const modelYear = useRef<string>('');
  const insuranceType = useRef<string>('');

  const carfaxFlags = useRef<string[] | undefined>(undefined);

  const carfaxInformation = useRef<ZebraListProps>(initialCarfaxInformation);

  const incidentInformation = useRef<ZebraListProps>(initialIncidentInfo);

  const callerContactInformation = useRef<ZebraListProps>(
    initialCallerContactInformation
  );

  const carOwnerContactInformation = useRef<ZebraListProps>(
    initialCarOwnerContactInformation
  );

  const carDriverContactInformation = useRef<ZebraListProps>(
    initialCarDriverContactInformation
  );

  const repairShopInformation = useRef<ZebraListProps>(
    initialRepairShopInformation
  );

  const assignmentDurationInformation = useRef<ZebraListProps>(
    initialAssignmentDurationInformation
  );

  useEffect(() => {
    const getInvestigationDetails = async (controller: AbortController) => {
      const client = new CasesClient();
      client
        .getInvestigationDetails(caseId, controller.signal)
        .then((response) => {
          processInvestigationDetailsResponse(response);
          processIncidentLocation(response);
          setData(response);
        })
        .catch((error) => console.log(error));
    };

    const controller = new AbortController();

    getInvestigationDetails(controller);

    return () => controller.abort();
  }, [caseId]);

  const processInvestigationDetailsResponse = (
    response: GetInvestigationDetailsResponse
  ) => {
    waystarScore.current = response?.waystarScore ? response.waystarScore : 0;
    registrationNumber.current = response.registrationNumber ?? '';
    carBrand.current = response.brand ?? '';
    modelYear.current = response.vehicleYearModel ?? '';
    insuranceType.current = response.insuranceType ?? '';

    callerContactInformation.current.list[0].value =
      response.callerContactInfo ?? '';
    callerContactInformation.current.list[1].value =
      response.callerContactPhone ?? '';

    carOwnerContactInformation.current.list[0].value =
      response.ownerContactInfo ?? '';
    carOwnerContactInformation.current.list[1].value =
      response.ownerContactPhone ?? '';
    carOwnerContactInformation.current.list[2].value =
      response.ownerContactMobilePhone ?? '';

    incidentInformation.current.list[0].value = response.incidentType ?? '';
    incidentInformation.current.list[1].value = response.helpType ?? '';

    repairShopInformation.current.list[0].value =
      response.repairShopName ?? 'Ej lämnad på verkstad';

    repairShopInformation.current.list[1].value =
      response.actualDropOffLocation ?? '';

    assignmentDurationInformation.current.list[0].value = getAssignmentDuration(
      response.assignmentEndedAt,
      response.assignmentStartedAt
    );

    carfaxFlags.current = response.enabledCarfaxFlags;

    carfaxInformation.current.list[0].value =
      response.vehicleLegalOwners?.toString() ?? '';
    carfaxInformation.current.list[1].value = response.vehicleLastOwnerChangeDate
      ? dayjs(response.vehicleLastOwnerChangeDate).utc().format('YYYY-MM-DD')
      : '';
  };

  const processIncidentLocation = (
    response: GetInvestigationDetailsResponse
  ) => {
    const location = {
      streetAddress: response.streetAddress,
      locationTypeId: response.locationTypeId,
      locationType: response.locationType,
      locationTime: response.locationTime,
      latitude: response.latitude,
      longitude: response.longitude,
    } as DrivePathPoint;

    setIncidentLocation(location);
  };

  const modalStack = useModalStack();

  // console.log('SPACE');
  return (
    <InvestigationContainer>
      {data === undefined && <Loader center={true} />}
      {data && (
        <>
          <SubContainer>
            <WaystarScoreTitle>
              WayStar Score: {waystarScore.current.toFixed(2)}
              <FlagContent>
                {waystarScore.current > HIGH_WAYSTARSCORE && (
                  <BsFlagFill color={Colors.Red} />
                )}
                {waystarScore.current > MED_WAYSTARSCORE && (
                  <BsFlagFill color={Colors.Red} />
                )}
                {waystarScore.current > LOW_WAYSTARSCORE && (
                  <BsFlagFill color={Colors.Red} />
                )}
              </FlagContent>
            </WaystarScoreTitle>
            <Divider />
            <InformationContainer>
              <InformationColumn>
                <Text bold={true}>Regnr: {registrationNumber.current}</Text>
                <Text>
                  Bilmodell: {carBrand.current} {modelYear.current}
                </Text>
                <Text>Försäkring: {insuranceType.current}</Text>
                <Text>
                  Car.info:{' '}
                  <Link
                    target='_blank'
                    href={`https://www.car.info/sv-se/license-plate/S/${registrationNumber.current.replace(
                      ' ',
                      ''
                    )}`}
                  >
                    Klicka här
                  </Link>
                </Text>{' '}
                <Text>
                  Hitta.se:{' '}
                  <Link
                    href={`https://www.hitta.se/sök?vad=${carOwnerContactInformation.current.list[0].value}`}
                    target='_blank'
                  >
                    Klicka här
                  </Link>
                </Text>
                <Text>
                  Alla bolag:{' '}
                  <Link
                    href={`https://www.allabolag.se/what/${carOwnerContactInformation.current.list[0].value}`}
                    target='_blank'
                  >
                    Klicka här
                  </Link>
                </Text>
                {false && (
                  <Text>
                    Carfax rapport:{' '}
                    <Link href={`/case/cfr/${caseId}`} target='_blank'>
                      Klicka här
                    </Link>
                  </Text>
                )}
                <Text>
                  Annonshistorik:{' '}
                  <FakeLink
                    onClick={() => {
                      modalStack.push(<NoLicense popup={true} />);
                    }}
                  >
                    Klicka Här
                  </FakeLink>
                  {false && (
                    <Link href={`/case/ah/${caseId}`} target='_blank'>
                      Klicka här
                    </Link>
                  )}
                </Text>
                {/* <Text link={true}>Mer uppgifter om fordonet</Text> */}
                <Divider style={{ marginTop: Margins.Medium }} />
                {carfaxFlags.current?.map((flag, index) => {
                  let useDivider: boolean = false;
                  if (
                    carfaxFlags.current &&
                    index !== carfaxFlags.current.length - 1
                  ) {
                    useDivider = true;
                  }

                  return (
                    <FlagComponent
                      key={flag}
                      label={flag}
                      useDivider={useDivider}
                    />
                  );
                })}
              </InformationColumn>
              <InformationColumn>
                <InvestigationCheck
                  updateInvestigationCheck={updateInvestigationCheck}
                  shellCase={shellCase}
                />
                {(data?.customIncidentTypeDescription ||
                  data?.customHelpTypeDescription) && (
                  <TowDriverComment className={'comment speechbubble'}>
                    <>
                      {!!data?.customIncidentTypeDescription && (
                        <Text bold>{data?.customIncidentTypeDescription}</Text>
                      )}
                      {!!data?.customHelpTypeDescription && (
                        <Text bold>{data?.customHelpTypeDescription}</Text>
                      )}
                    </>
                  </TowDriverComment>
                )}
                <TowDriverInformation>
                  <TowDriverImage
                    height={80}
                    width={80}
                    src={TowDriverIcon}
                    alt='Circular placeholder image of a towdriving truck'
                  />
                  <TowDriverContact>
                    <Text bold={true}>{data?.towDriverName}</Text>
                    <Text>{data?.towDriverPhone}</Text>
                    <Text></Text>
                  </TowDriverContact>
                </TowDriverInformation>
              </InformationColumn>
            </InformationContainer>
          </SubContainer>
          <MediaSubContainer>
            <ImageMapRow>
              {incidentLocation !== undefined &&
                incidentLocation.latitude != null &&
                incidentLocation.longitude != null && (
                  <MapContainer>
                    {scriptLoaded && (
                      <Map
                        center={{
                          lat: incidentLocation.latitude,
                          lng: incidentLocation.longitude,
                        }}
                        zoom={9}
                        markers={{
                          [incidentLocation.reportLocationId!]: {
                            marker: new google.maps.Marker({
                              position: new google.maps.LatLng(
                                incidentLocation.latitude,
                                incidentLocation.longitude
                              ),
                              zIndex: 0,
                              optimized: false,
                              icon: getReportLocationMarkerSvg(
                                incidentLocation.locationTypeId!,
                                incidentLocation.isLocationRepairShop
                              ),
                            }),
                          },
                        }}
                      />
                    )}
                  </MapContainer>
                )}
              <ImageContainer>
                {data?.imageIds !== undefined && data?.imageIds.length > 0 && (
                  <ImageCarousel ids={data?.imageIds} />
                )}
              </ImageContainer>
            </ImageMapRow>
          </MediaSubContainer>
          <SubContainer>
            <Divider />
            <InformationContainer style={{ marginTop: 0 }}>
              <InformationColumn>
                <InfoTitle>Kontaktuppgifter</InfoTitle>
              </InformationColumn>
              <InformationColumn>
                <InfoTitle>Olycka</InfoTitle>
              </InformationColumn>
            </InformationContainer>
            <InformationContainer style={{ marginTop: 0 }}>
              <InformationColumn>
                <ZebraList
                  title={callerContactInformation.current.title}
                  list={callerContactInformation.current.list}
                  firstColumnWidth={FirstColumnWidth.Small}
                />
                <ZebraList
                  marginTop={10}
                  title={carOwnerContactInformation.current.title}
                  list={carOwnerContactInformation.current.list}
                  firstColumnWidth={FirstColumnWidth.Small}
                />
                <ZebraList
                  marginTop={10}
                  title={carDriverContactInformation.current.title}
                  list={carDriverContactInformation.current.list}
                  firstColumnWidth={FirstColumnWidth.Small}
                />
              </InformationColumn>
              <InformationColumn>
                <ZebraList
                  title={incidentInformation.current.title}
                  list={incidentInformation.current.list}
                  firstColumnWidth={FirstColumnWidth.Small}
                />
                <ZebraList
                  marginTop={10}
                  title={repairShopInformation.current.title}
                  list={repairShopInformation.current.list}
                  firstColumnWidth={FirstColumnWidth.Small}
                />
                <ZebraList
                  marginTop={10}
                  title={carfaxInformation.current.title}
                  list={carfaxInformation.current.list}
                  firstColumnWidth={FirstColumnWidth.Small}
                />
                <ZebraList
                  marginTop={10}
                  title={assignmentDurationInformation.current.title}
                  list={assignmentDurationInformation.current.list}
                  firstColumnWidth={FirstColumnWidth.Small}
                />
              </InformationColumn>
            </InformationContainer>
          </SubContainer>
          {false && (
            <VehicleHistoryContainer
              registrationNumber={registrationNumber.current}
              caseId={caseId}
            />
          )}
        </>
      )}
    </InvestigationContainer>
  );
};

export default InvestigationComponent;
