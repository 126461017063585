import styled from 'styled-components';
import { Colors, Margins } from '../../utils/styles';

export const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
`;

export const SubContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 60%;
  min-width: 550px;
`;

export const Divider = styled.div`
  border-bottom: 1px solid ${Colors.DividerGrey};
  width: 100%;
`;

export const Spacer = styled.div`
  width: '100%';
  height: ${Margins.Medium};
`;