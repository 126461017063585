import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { FirstColumnWidth, Margins } from '../../../utils/styles';
import {
  CasesClient,
  DrivePathPoint,
  GetProcessingDetailsResponse,
  IncidentLocationResponse,
  LocationType,
} from '../../api-clients';
import Map from '../../GoogleMaps/Map';
import ZebraList, {
  ZebraListItem,
  ZebraListProps,
} from '../../ZebraList/ZebraList';
import {
  emptyZebraListItem,
  initialDrivenPaths,
  initialDrivePaths,
  initialPrice,
  initialPriceBaseValues,
} from './emptyData';
import {
  getReportLocationMarker,
  InfoWindowContent,
} from '../../GoogleMaps/utils/GoogleMapsUtils';
import { Container, Divider, SubContainer } from '../styles';
import { H1 } from '../../styled';
import { useGoogleMapsApi } from '../../GoogleMaps/GoogleMapsApiContext';
import dayjs from 'dayjs';
import Loader from '../../Loader';

interface Props {
  caseId: number;
}

interface GetStreetAddressRequest {
  id: number;
  latitude: number;
  longitude: number;
  drivePathIndex: number;
  doGeocode: boolean;
  streetAddress?: string;
  city?: string;
}

const ProcessingContainer = styled(Container)`
  padding-bottom: 60px;
`;

const MapContainer = styled.div`
  width: calc(100% - ${Margins.ExtraLarge});
  height: 60vh;
  margin-top: ${Margins.Large};
`;

const Title = styled(H1)`
  margin-top: ${Margins.Double};
  margin-bottom: 10px;
`;

const ListDivider = styled(Divider)`
  width: 60%;
  min-width: 550px;
`;

const ProcessingComponent: React.FC<Props> = ({ caseId }) => {
  const { scriptLoaded } = useGoogleMapsApi();
  const drivePaths = useRef<ZebraListProps>(initialDrivePaths());
  const priceBaseValues = useRef<ZebraListProps>(initialPriceBaseValues());
  const drivenPaths = useRef<ZebraListProps>(initialDrivenPaths());
  const price = useRef<ZebraListProps>(initialPrice());
  const numResponseDrivePaths = useRef<number>(0);

  var markerClickFunc = (id: string) => {};

  const [incidentLocation, setIncidentLocation] = useState<
    IncidentLocationResponse | undefined
  >(undefined);

  const [pointsOfInterest, setPointsOfInterest] = useState<
    DrivePathPoint[] | undefined
  >(undefined);

  const [fetchAddresses, setFetchAddresses] = useState<
    GetStreetAddressRequest[]
  >([]);

  useEffect(() => {
    const getDrivePathAddress = async (controller: AbortController) => {
      const client = new CasesClient();
      client
        .getStreetAddress(
          fetchAddresses[0].id,
          fetchAddresses[0].latitude,
          fetchAddresses[0].longitude,
          fetchAddresses[0].doGeocode,
          fetchAddresses[0].streetAddress,
          fetchAddresses[0].city,
          controller.signal
        )
        .then((response) => {
          if (response && response.streetAddress) {
            let dpProps = drivePaths.current;

            var newFetchArr = fetchAddresses.map((item) => {
              if (
                item.latitude === fetchAddresses[0].latitude &&
                item.longitude === fetchAddresses[0].longitude
              ) {
                let newItem = item;
                newItem.doGeocode = false;
                newItem.streetAddress = response.streetAddress;
                newItem.city = response.city;
                dpProps.list[item.drivePathIndex].name = '';
                dpProps.list[
                  item.drivePathIndex
                ].value = response.streetAddress!;

                return null;
              }

              return item;
            });

            drivePaths.current = dpProps;
            setFetchAddresses(
              newFetchArr.reduce(
                (acc, curr: GetStreetAddressRequest | null) => {
                  if (curr == null) {
                    return acc;
                  }
                  return [...acc, curr];
                },
                [] as GetStreetAddressRequest[]
              )
            );
          }
        })
        .catch((error) => console.log(error));
    };

    const controller = new AbortController();
    console.log(fetchAddresses);

    if (fetchAddresses.length > 0) {
      getDrivePathAddress(controller);
    }

    return () => controller.abort();
  }, [fetchAddresses]);

  useEffect(() => {
    const getProcessingDetails = async (controller: AbortController) => {
      const client = new CasesClient();
      client
        .getProcessingDetails(caseId, controller.signal)
        .then((response) => {
          processProcessingDetailsResponse(response);
          processIncidentLocation(response.incidentLocation);
          processReportLocations(response.drivePath);
        })
        .catch((error) => console.log(error));
    };

    const controller = new AbortController();

    getProcessingDetails(controller);

    return () => {
      controller.abort();
      drivePaths.current = initialDrivePaths();
      priceBaseValues.current = initialPriceBaseValues();
      drivenPaths.current = initialDrivenPaths();
      price.current = initialPrice();
      setIncidentLocation(undefined);
      setPointsOfInterest(undefined);
      setFetchAddresses([]);
    };
  }, [caseId]);

  const processProcessingDetailsResponse = (
    response: GetProcessingDetailsResponse
  ) => {
    drivePaths.current.list = drivePaths.current.list.splice(0, 3);
    numResponseDrivePaths.current = response.drivePath
      ? response.drivePath.length
      : 0;

    // Sort in place the drivePaths by date ascending
    response.drivePath?.sort(function (a, b) {
      if (a.locationTime! > b.locationTime!) return 1;
      if (a.locationTime! < b.locationTime!) return -1;
      return 0;
    });

    let fetchArr: GetStreetAddressRequest[] = [];

    response.drivePath?.map((dp, index) => {
      let addLoader = false;
      if (
        (!dp.streetAddress && dp.latitude && dp.longitude) ||
        (dp.streetAddress === 'Saknas' && dp.latitude && dp.longitude)
      ) {
        addLoader = true;
        fetchArr.push({
          id: dp.id!,
          latitude: dp.latitude,
          longitude: dp.longitude,
          drivePathIndex: (index + 1) * 3,
          doGeocode: true,
        });
      }

      const streetAddress = dp.streetAddress ?? '';

      if (addLoader) {
        drivePaths.current.list.push({
          name: 'LOADER',
          value: (
            <span style={{ color: '#aaaaaa' }}>
              {dp.latitude}, {dp.longitude}
            </span>
          ),
          flex: drivePaths.current.list[0].flex,
          index: dp.reportLocationId,
        });
      } else {
        drivePaths.current.list.push({
          name: '',
          value: streetAddress,
          flex: drivePaths.current.list[0].flex,
          index: dp.reportLocationId,
        });
      }

      drivePaths.current.list.push({
        name: '',
        value: dp.locationType ?? '',
        flex: drivePaths.current.list[1].flex,
        index: dp.reportLocationId,
      });
      drivePaths.current.list.push({
        name: '',
        value: `${dayjs(dp.locationTime).format('YYYY-MM-DD HH:mm:ss')}`,
        flex: drivePaths.current.list[2].flex,
        alignRight: true,
        index: dp.reportLocationId,
      });
    });

    if (response.invoices)
      price.current = {
        title: 'Pris',
        columns: 5,
        list: [
          { name: '', value: '', flex: 4, title: <b>Specifikation</b> },
          { name: '', value: '', flex: 1, title: <b>Antal</b> },
          { name: '', value: '', flex: 2, title: <b>Enhet</b> },
          {
            name: '',
            value: '',
            flex: 2,
            title: <b>a-pris</b>,
            alignRight: true,
          },
          {
            name: '',
            value: '',
            flex: 2,
            title: <b>Summa</b>,
            alignRight: true,
          },
        ],
      } as ZebraListProps;

    let sum = 0;
    response.invoices?.forEach((invoice, invoiceIndex) => {
      invoice.invoiceLines?.forEach((line) => {
        price.current.list.push({
          name: '',
          value: line.axItemName ?? '',
          flex: 4,
        });
        price.current.list.push({ name: '', value: line.qty ?? '', flex: 1 });
        price.current.list.push({
          name: '',
          value: line.salesUnit ?? '',
          flex: 2,
        });
        price.current.list.push({
          name: '',
          value: line.price ? line.price.toFixed(2) : '',
          flex: 2,
          alignRight: true,
        });
        price.current.list.push({
          name: '',
          value: line.lineAmount ? line.lineAmount.toFixed(2) : '',
          flex: 2,
          alignRight: true,
        });
        var _price: number = line.price
          ? parseFloat(line.price!.toFixed(2))
          : 0;
        sum = sum + _price;
      });

      const emptyPriceLine = emptyZebraListItem(5, [4, 1, 2, 2, 2]);
      price.current.list = [...price.current.list, ...emptyPriceLine];

      price.current.list.push({ name: '', value: <b>Summa</b>, flex: 4 });
      price.current.list.push({ name: '', value: '' });
      price.current.list.push({ name: '', value: '', flex: 2 });
      price.current.list.push({ name: '', value: '', flex: 2 });
      price.current.list.push({
        name: '',
        value: sum.toFixed(2),
        flex: 2,
        alignRight: true,
      });
      price.current.list.push({ name: '', value: <b>Moms</b>, flex: 4 });
      price.current.list.push({ name: '', value: '' });
      price.current.list.push({ name: '', value: '', flex: 2 });
      price.current.list.push({ name: '', value: '', flex: 2 });
      price.current.list.push({
        name: '',
        value: invoice.taxAmount ? invoice.taxAmount.toFixed(2) : '',
        flex: 2,
        alignRight: true,
      });

      price.current.list.push({ name: '', value: <b>Total</b>, flex: 4 });
      price.current.list.push({ name: '', value: '', flex: 1 });
      price.current.list.push({ name: '', value: '', flex: 2 });
      price.current.list.push({ name: '', value: '', flex: 2 });
      price.current.list.push({
        name: '',
        value: invoice.invoiceAmount ? invoice.invoiceAmount.toFixed(2) : '',
        flex: 2,
        alignRight: true,
      });

      if (
        response.invoices &&
        response.invoices?.length > 1 &&
        invoiceIndex + 1 !== response.invoices.length
      ) {
        price.current.list = [
          ...price.current.list,
          ...emptyPriceLine,
          ...emptyPriceLine,
          ...emptyPriceLine,
        ];
      }
    });

    setFetchAddresses(fetchArr);
  };

  const processIncidentLocation = (
    incidentLocation: IncidentLocationResponse | undefined
  ) => {
    if (incidentLocation !== undefined) {
      setIncidentLocation(incidentLocation);
    }
  };

  const processReportLocations = (
    reportLocations: DrivePathPoint[] | undefined
  ) => {
    if (reportLocations) {
      const pointsOfInterestToSkip = [
        LocationType.ResumingAssignment,
        LocationType.PausingAssignment,
        LocationType.Returning,
      ];

      // Take out report locations we arent interested in creating markers for.
      let validPointsOfInterest = reportLocations?.filter(
        (f) => !pointsOfInterestToSkip.includes(f.locationTypeId!)
      );

      // Take only the report locations that have coordinates we can plot.
      validPointsOfInterest = validPointsOfInterest.filter(
        (f) => f.latitude !== undefined && f.longitude !== undefined
      );

      setPointsOfInterest(validPointsOfInterest);
    }
  };

  var onZebraListClick = (item: ZebraListItem) => {
    markerClickFunc(item.index!.toString());
  };

  return (
    <ProcessingContainer>
      {incidentLocation === undefined && <Loader center={true} />}
      {incidentLocation && (
        <>
          <Title>Körsträcka</Title>
          <SubContainer>
            <ZebraList
              title={drivePaths.current.title}
              columns={drivePaths.current.columns}
              list={drivePaths.current.list}
              showListIndex={drivePaths.current.showListIndex}
              // HACK: If there is no incident location, no map can be shown, and the zebra clicks have no usage
              onClick={
                !!incidentLocation?.latitude && !!incidentLocation?.longitude
                  ? onZebraListClick
                  : undefined
              }
            />
          </SubContainer>
          {incidentLocation !== undefined &&
            !!incidentLocation.latitude &&
            !!incidentLocation.longitude &&
            incidentLocation.latitude !== 0 &&
            incidentLocation.longitude !== 0 &&
            pointsOfInterest !== undefined && (
              <MapContainer>
                {scriptLoaded && (
                  <Map
                    center={
                      new google.maps.LatLng(
                        incidentLocation.latitude,
                        incidentLocation.longitude
                      )
                    }
                    addIndex={
                      numResponseDrivePaths.current -
                      pointsOfInterest.reduce((sum, val) => {
                        if (
                          !!val.latitude &&
                          !!val.longitude &&
                          val.latitude !== 0 &&
                          val.longitude !== 0
                        ) {
                          return sum + 1;
                        } else {
                          return sum;
                        }
                      }, 0)
                    }
                    markers={pointsOfInterest.reduce(
                      (acc, curr, index) => {
                        if (
                          !!curr.latitude &&
                          !!curr.longitude &&
                          curr.latitude !== 0 &&
                          curr.longitude !== 0
                        ) {
                          var data = {} as {
                            info: InfoWindowContent;
                            marker: google.maps.Marker;
                          };
                          data.info = {
                            title: curr.locationType!,
                            time: curr.locationTime
                              ? dayjs(curr.locationTime).format(
                                  'YYYY-MM-DD HH:mm:ss'
                                )
                              : '',
                            descriptiveTitle: curr.descriptiveTitle ?? null,
                            descriptiveContent: curr.descriptiveContent ?? null,
                          } as InfoWindowContent;

                          data.marker = new google.maps.Marker({
                            position: new google.maps.LatLng(
                              curr.latitude!,
                              curr.longitude!
                            ),
                            zIndex: 0,
                            optimized: false,
                            icon: getReportLocationMarker(index + 1),
                          });
                          acc[curr.reportLocationId!] = data;
                        }
                        return acc;
                      },
                      {} as {
                        [key: string]: {
                          marker: google.maps.Marker;
                          info: InfoWindowContent;
                        };
                      }
                    )}
                    setMarkerClickFunction={(onMarkerClick) => {
                      markerClickFunc = onMarkerClick;
                    }}
                    zoom={11}
                  />
                )}
              </MapContainer>
            )}
          <Title>Pris</Title>
          <ListDivider />
          <SubContainer>
            <ZebraList
              marginTop={20}
              title={price.current.title}
              columns={price.current.columns}
              list={price.current.list}
            />
          </SubContainer>
        </>
      )}
    </ProcessingContainer>
  );
};

export default ProcessingComponent;
