import { VehicleHistoryTimelineDTO } from '../api-clients/index';
import React from 'react';
import styled, { css } from 'styled-components';
import { Colors, FontFamily, FontSize } from '../../utils/styles';

const Container = styled.div<{ nrOfBoxes: number; maxBoxHeight: number }>`
  display: block;
  position: relative;
  margin-left: 64px;
  margin-right: 5px;
  ${(props) =>
    props.maxBoxHeight &&
    css`
      height: ${props.maxBoxHeight + BOX_HEIGHT + BOX_GAP + TOP_MARGIN}px;
    `}
`;

const Line = styled.div<{
  percent: number;
  top: number;
}>`
  position: absolute;
  width: 3px;
  height: ${(props) => props.top}px;
  background-color: transparent;
  top: -12px;
  left: ${(props) => props.percent}%;
  z-index: 1;
`;

const Box = styled.div<{
  top: number;
  percent: number;
  color: string;
}>`
  position: absolute;
  width: 124px;
  height: 80px;
  background-color: ${(props) => props.color};
  top: ${(props) => props.top}px;
  left: calc(${(props) => props.percent}% - 62px);
  text-align: center;
  z-index: 2;
  border: 1px solid ${Colors.DarkGrey};
`;

const BoxTitle = styled.h1`
  font-size: ${FontSize.Medium};
  font-weight: bold;
`;

const BoxEvent = styled.h1`
  font-size: ${FontSize.Medium};
  font-weight: normal;
`;

interface VehicleHistoryBoxProps {
  lines: number[];
  fullYearLines: number[];
  yearsArr: number[];
  timeline: VehicleHistoryTimelineDTO[];
}
const BOX_HEIGHT = 80;
const BOX_GAP = 12;
const TOP_MARGIN = 20;

const VehicleHistoryBox: React.FC<VehicleHistoryBoxProps> = ({
  lines,
  fullYearLines,
  yearsArr,
  timeline,
}) => {
  let top = TOP_MARGIN;
  let lastRenderedYear = 1969;
  let nrBoxesLastYear = 0;
  let nrBoxesThisYear = 0;
  let nrBoxesNextYear = 0;
  let thisYearsTop = 0;
  let maxNrOfBoxes = 0;
  let maxBoxHeight = TOP_MARGIN;

  const renderBox = (tl: VehicleHistoryTimelineDTO, index: number) => {
    const date = new Date(tl.timestamp! * 1000);
    const thisYear = date.getFullYear();

    if (thisYear === lastRenderedYear) {
      top += BOX_HEIGHT + BOX_GAP;
    } else if (thisYear >= lastRenderedYear + 1 || lastRenderedYear === 1969) {
      nrBoxesNextYear = 0;

      if (thisYear !== lastRenderedYear + 1) {
        nrBoxesLastYear = 0;
      } else {
        nrBoxesLastYear = nrBoxesThisYear;
      }

      nrBoxesThisYear = 1;
      if (nrBoxesThisYear > maxNrOfBoxes) {
        maxNrOfBoxes = nrBoxesThisYear;
      }
      let countBoxesThisYear = true;
      let i = 1;

      while (countBoxesThisYear) {
        if (thisYear === yearsArr[index + i]) {
          nrBoxesThisYear++;
          if (nrBoxesThisYear > maxNrOfBoxes) {
            maxNrOfBoxes = nrBoxesThisYear;
          }
          i++;
        } else {
          countBoxesThisYear = false;
        }
      }

      i = 1;
      let stop = false;

      while (!stop) {
        if (thisYear + 1 === yearsArr[index + i]) {
          nrBoxesNextYear++;
          i++;
        } else if (thisYear + 1 > yearsArr[index + i]) {
          i++;
        } else {
          stop = true;
        }
      }

      if (nrBoxesLastYear > 0) {
        if (
          nrBoxesThisYear * (BOX_HEIGHT + BOX_GAP) <=
          top - BOX_HEIGHT + BOX_GAP
        ) {
          top = TOP_MARGIN;
        } else {
          top = top + BOX_HEIGHT + BOX_GAP;
        }
      } else {
        top = TOP_MARGIN;
      }

      thisYearsTop = top;
      if (thisYearsTop > maxBoxHeight) {
        maxBoxHeight = thisYearsTop;
      }
    } else {
      top = TOP_MARGIN;

      nrBoxesLastYear = 0;
    }

    lastRenderedYear = thisYear;
    const line = renderLine(index, lines[index], thisYearsTop, top);

    console.log('RENDER! 2');
    return (
      <React.Fragment key={index}>
        {line}
        <Box
          top={top}
          percent={fullYearLines[index]}
          color={`${Colors.Background}`}
        >
          <BoxTitle>{`${thisYear}-${
            date.getMonth() + 1
          }-${date.getDate()}`}</BoxTitle>
          <BoxEvent>{tl.event}</BoxEvent>
        </Box>
      </React.Fragment>
    );
  };

  const renderLine = (
    index: number,
    percent: number,
    top: number,
    boxTop: number
  ) => {
    return (
      <Line key={index + 1000} percent={percent} top={TOP_MARGIN + boxTop}>
        <svg height={TOP_MARGIN + boxTop} width='3'>
          <g fill='none' stroke='black'>
            <line
              x1={0}
              x2={0}
              y1={boxTop + TOP_MARGIN}
              y2={1}
              stroke='black'
              strokeDasharray='3, 3'
              strokeWidth='2.5'
            />
          </g>
        </svg>
      </Line>
    );
  };

  var boxes = timeline.map((tl, index) => renderBox(tl, index));
  return (
    <Container maxBoxHeight={maxBoxHeight} nrOfBoxes={maxNrOfBoxes}>
      {boxes.map((f) => f)}
    </Container>
  );
};

export default VehicleHistoryBox;
