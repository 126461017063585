import React, { Fragment, useContext, useState } from 'react';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

import { ApplicationPaths } from './ApiAuthorizationConstants';
import styled from 'styled-components';
import { Colors, FontSize } from '../../utils/styles';
import { BasicDataContext } from '../../utils/useBasicData';

const LogoutBox = styled.div`
  background: transparent;
  font-weight: normal;
  font-size: ${FontSize.Medium};
  text-transform: uppercase;
  border: none;
  margin-right: 40px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

const StyledNavLink = styled.span`
  text-decoration: none;
  color: ${Colors.DarkGreyContrast};
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;
const LoginMenu = (props) => {
  const { user } = useContext(BasicDataContext);

  const [isAuthenticated, setIsAuthenticiated] = useState(!!user);
  const [userName, setUserName] = useState(user ? user.name : null);
  const authenticatedView = (logoutPath) => {
    return (
      <Fragment>
        <LogoutBox>
          <StyledNavLink
            onClick={() => (window.location.href = logoutPath.pathname)}
            className='text-dark'
          >
            Logout
          </StyledNavLink>
        </LogoutBox>
      </Fragment>
    );
  };

  if (isAuthenticated) {
    const logoutPath = {
      pathname: `${ApplicationPaths.LogOut}`,
      state: { local: true },
    };
    return authenticatedView(logoutPath);
  } else {
    return null;
  }
};

export default LoginMenu;
