import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { Colors, FontFamily, FontSize } from '../utils/styles';
import logo from '../images/logo.png';
import LoginMenu from './api-authorization/LoginMenu';
// import authService from './api-authorization/AuthorizeService';
import { CasesContext } from './Cases/CasesContext';
import { BasicDataContext, RouteContent } from '../utils/useBasicData';

enum TabType {
  CASES = 'ÄRENDEN',
  INCIDENTS_MAP = 'KARTA',
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 5px solid ${Colors.Red};
  margin-bottom: 0;
  padding-bottom: 0;
  margin-top: -2px;
`;

const MiddleSection = styled.div`
  display: inline-flex;
  flex-direction: row;
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 20px;
  border-left: 2px solid ${Colors.LightGrey};
`;

const UserBox = styled.div`
  display: inline-flex;
  margin-left: auto;
  padding-right: 40px;
  color: ${Colors.Black};
  font-size: ${FontSize.Large};
  text-align: center;
  justify-content: center;
  align-items: center;
`;

const SupportBox = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  text-align: right;
  color: ${Colors.White};
  background-color: ${Colors.Red};
  font-weight: bold;
  padding-right: 26px;
  width: 240px;
  height: 85px;
`;

const Logo = styled.img`
  width: 100%;
  height: auto;
  max-width: 200px;
  object-fit: cover;
  overflow: hidden;
  align-self: center;
  padding-right: 24px;
`;

const ButtonSection = styled.div`
  display: inline-flex;
  padding-left: 35px;
`;

const LineDiv = styled.div`
  display: inline-flex;
  flex-direction: row;
  height: auto;
  width: 100%;
  margin-left: 30px;
  border-bottom: 2px solid ${Colors.LightGrey};
`;

const ButtonDiv = styled.div<{ active?: boolean }>`
  align-items: center;
  justify-content: center;
  margin-right: 40px;
  font-size: ${FontSize.Medium};
  font-weight: normal;
  color: ${Colors.DarkGreyContrast};
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  user-select: none;
  cursor: pointer;
  &:hover {
    color: ${Colors.Black};
  }
`;

const ActiveButtonDiv = styled.div`
  align-items: center;
  justify-content: center;
  margin-right: 40px;
  font-size: ${FontSize.Medium};
  font-weight: bold;
  color: ${Colors.Black};
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  user-select: none;
  &:before {
    position: absolute;
    content: '';
    left: -3px;
    bottom: 16px;
    z-index: 0;
    height: 20px;
    width: 40px;
    border-top: 4px solid ${Colors.Red};
  }
`;

const InfoContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: auto;
`;

const AdminSelector = styled.div`
  flex: 1;
  margin-right: 20px;
`;

export const NavMenu: React.FC = () => {
  const [activeTab, setActiveTab] = useState<TabType | undefined>(undefined);
  const [fullName, setFullName] = useState<string>('');
  const { user, companies, updateUser, routeContent } = useContext(
    BasicDataContext
  );
  const history = useHistory();

  const { loadSettings } = useContext(CasesContext);

  const changeUserCompany = (e: any) => {
    updateUser(e.target.value);
  };

  useEffect(() => {
    // const subscription = authService.subscribe(() => {
    //   getUser();
    // });

    if (window.location.pathname == '/') {
      setActiveTab(TabType.CASES);
    } else if (window.location.pathname == '/incidents') {
      setActiveTab(TabType.INCIDENTS_MAP);
    }
    return () => {
      // authService.unsubscribe(subscription);
    };
  }, []);

  useEffect(() => {
    const unRegister = history.listen((location, _) => {
      switch (location.pathname) {
        case '/':
          loadSettings();
          setActiveTab(TabType.CASES);
          break;
        case '/incidents':
          setActiveTab(TabType.INCIDENTS_MAP);
          break;
        default:
          setActiveTab(undefined);
          break;
      }
    });

    switch (history.location.pathname) {
      case '/':
        loadSettings();
        setActiveTab(TabType.CASES);
        break;

      case '/incidents':
        setActiveTab(TabType.INCIDENTS_MAP);
        break;
    }

    return () => unRegister();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const redirect = (tab: TabType) => {
    setActiveTab(tab);
    let target;
    switch (tab) {
      case TabType.CASES:
        target = '/';
        break;

      case TabType.INCIDENTS_MAP:
        target = '/incidents';
        break;
    }

    history.push(target);
  };

  return (
    <header>
      <Container>
        <MiddleSection>
          <LineDiv>
            <Logo
              src={logo}
              onClick={() => {
                if (
                  !!routeContent.find((f) => f === RouteContent.ViewContent)
                ) {
                  if (window.location.pathname !== '/') {
                    history.push('/');
                  }
                }
              }}
              alt='logo'
            />
            <InfoContainer>
              <UserBox>{fullName}</UserBox>
              {user && user.roles!.find((f) => f === 'SystemAdmin') && (
                <AdminSelector>
                  <select value={user.companyID} onChange={changeUserCompany}>
                    {companies?.map((f) => (
                      <option value={f.id}>{f.name}</option>
                    ))}
                  </select>
                </AdminSelector>
              )}
              <LoginMenu></LoginMenu>
            </InfoContainer>
          </LineDiv>
          <SupportBox>
            <span>SUPPORT</span>
            <span>070 – 311 14 59</span>
            <span>info@waystar.ai</span>
          </SupportBox>
        </MiddleSection>
        {!!routeContent.find((f) => f === RouteContent.ViewContent) && (
          <ButtonSection>
            {Object.values(TabType).map((tab, index) => {
              if (activeTab === tab) {
                return (
                  <ActiveButtonDiv key={index}>
                    {tab.toString()}
                  </ActiveButtonDiv>
                );
              } else {
                return (
                  <ButtonDiv key={index} onClick={() => redirect(tab)}>
                    {tab.toString()}
                  </ButtonDiv>
                );
              }
            })}
          </ButtonSection>
        )}
      </Container>
    </header>
  );
};
