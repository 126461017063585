import React, { createContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  BasicDataClient,
  BasicDataModel,
  CompanyDTO,
  UserModel,
} from '../components/api-clients';
import Loader from '../components/Loader';

interface Props {
  user?: UserModel;
  companies?: CompanyDTO[];
  updateUser: (companyId: number) => void;
  routeContent: RouteContent[];
}

const initialContext = {
  user: undefined,
  companies: undefined,
  updateUser: () => {},
  routeContent: [],
};

const SpinnerContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-content: center;
  position: relative;
  &.centered {
    width: 450px;
    height: 470px;
  }
  &.modal {
    height: calc(100vh - 300px);
    width: 470px;
  }
`;
export enum UserRole {
  Investigator = 'Investigator',
  SystemAdmin = 'SystemAdmin',
  ApiUser = 'ApiUser',
  CaseWorker = 'CaseWorker',
}

export enum RouteContent {
  ViewContent = 1,
  ApiContent = 2,
}

export const BasicDataContext = createContext<Props>(initialContext);

const BasicDataProvider: React.FC = ({ children }) => {
  const [basicData, setBasicData] = useState<BasicDataModel | undefined>(
    undefined
  );

  const fetchBasicData = async () => {
    const client = new BasicDataClient();
    var resp = await client.getBasicData();
    setBasicData(resp);
  };

  useEffect(() => {
    fetchBasicData();
  }, []);

  if (!basicData) {
    return (
      <SpinnerContainer>
        <Loader />
      </SpinnerContainer>
    );
  }

  const viewContent: RouteContent[] = [];
  if (!!basicData.currentUser) {
    basicData.currentUser.roles?.forEach((role) => {
      console.log(role, UserRole.Investigator, role == UserRole.Investigator);
      if (
        role == UserRole.Investigator ||
        role === UserRole.SystemAdmin ||
        role === UserRole.CaseWorker
      ) {
        if (!viewContent.find((f) => f == RouteContent.ViewContent))
          viewContent.push(RouteContent.ViewContent);
      }
      if (role == UserRole.ApiUser) {
        if (!viewContent.find((f) => f == RouteContent.ApiContent))
          viewContent.push(RouteContent.ApiContent);
      }
    });
  }

  const updateUser = async (companyId: number) => {
    const client = new BasicDataClient();
    var resp = await client.updateUser(companyId);
    setBasicData(resp);
    window.location.reload();
  };

  return (
    <BasicDataContext.Provider
      value={{
        user: basicData!.currentUser!,
        companies: basicData!.companies,
        updateUser,
        routeContent: viewContent,
      }}
    >
      {basicData && children}
    </BasicDataContext.Provider>
  );
};

export default BasicDataProvider;
