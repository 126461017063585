import { CSSProperties } from 'react';
import { CaseComponentColumnType } from './types';

export const caseComponentDefinition = [
  { type: CaseComponentColumnType.SPAN, flex: 1 },
  { type: CaseComponentColumnType.STAR, flex: 1 },
  {
    type: CaseComponentColumnType.SCORE,
    flex: 2,
    property: 'waystarScore',
  },
  { type: CaseComponentColumnType.FLAG, flex: 3 },
  { type: CaseComponentColumnType.IMAGE, flex: 3, property: 'imageURIs[0]' },
  {
    type: CaseComponentColumnType.SPAN,
    flex: 4,
    property: 'registrationNumber',
  },
  { type: CaseComponentColumnType.SPAN, flex: 10, property: 'brand' },
  { type: CaseComponentColumnType.SPAN, flex: 12, property: 'streetAddress' },
  {
    type: CaseComponentColumnType.SPAN,
    flex: 9,
    property: 'incidentType',
  },
  {
    type: CaseComponentColumnType.DATE,
    flex: 7,
    property: 'crashDate', // SHOULD BE UPDATED
    style: {
      textAlign: 'right',
    } as CSSProperties,
  },
  {
    type: CaseComponentColumnType.DATE,
    flex: 7,
    property: 'created', // SHOULD BE UPDATED
    style: {
      textAlign: 'right',
    } as CSSProperties,
  },
  { type: CaseComponentColumnType.SPAN, flex: 1 },
];
