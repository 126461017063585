import React from 'react';
import styled from 'styled-components';
import { Colors, FontFamily, FontSize } from '../../utils/styles';
import LineLoader from '../LineLoader';

const Container = styled.div<{
  marginTop?: number;
}>`
  display: inline-flex;
  flex-direction: column;
  margin-top: ${(props) => (props.marginTop ? `${props.marginTop}px` : '')};
`;

const Row = styled.div<{
  setBackground: boolean;
  usePointer?: boolean;
}>`
  display: inline-flex;
  flex-direction: row;
  background-color: ${(props) =>
    props.setBackground ? Colors.WhiteShade : Colors.Background};
  width: calc(100% - 10px);
  cursor: ${(props) => (props.usePointer ? 'pointer' : 'initial')};
`;

const Col = styled.div<{
  width?: number;
  flex?: number;
  paddingLeft?: number;
  paddingRight?: number;
  alignRight?: boolean;
}>`
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  flex: ${(props) => (props.flex ? props.flex : '')};
  text-align: ${(props) => (props.alignRight ? 'right' : 'left')};
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
  padding-bottom: 4px;
`;

const Gap = styled.div`
  height: inherit;
  width: 20px;
  background-color: ${Colors.Background};
  color: ${Colors.Background};
  padding-top: 8px;
  padding-bottom: 4px;
`;

const ColRow = styled.div<{
  flex: number;
}>`
  display: inline-flex;
  flex-direction: row;
  flex: ${(props) => props.flex};
`;

const Title = styled.h5`
  font-weight: bold;
  font-size: ${FontSize.ZebraTitle};
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 10px;
  letter-spacing: 0.5px;
`;

const Filler = styled.span`
  user-select: none;
  color: transparent;
  background-color: transparent;
`;

export interface ZebraListProps {
  marginTop?: number;
  startWithWhite?: boolean;
  columns?: number;
  showListIndex?: boolean;
  title: string;
  list: ZebraListItem[];
  firstColumnWidth?: number;
  onClick?: (item: ZebraListItem) => void;
}

export interface ZebraListItem {
  name: string;
  value: React.ReactText | JSX.Element;
  flex?: number;
  title?: string | JSX.Element;
  alignRight?: boolean;
  index?: string | number;
}

const ZebraList: React.FC<ZebraListProps> = (props) => {
  const {
    title,
    list,
    marginTop,
    startWithWhite,
    columns,
    showListIndex,
    firstColumnWidth,
    onClick,
  } = props;
  const start = startWithWhite ? 1 : 0;
  let subTitleRow: {
    title: string | JSX.Element;
    flex: number;
    alignRight?: boolean;
  }[] = [];
  if (columns && list[0].title) {
    for (let i = 0; i < columns; i++) {
      if (list[i].title && list[i].flex) {
        subTitleRow.push({
          title: list[i].title!,
          flex: list[i].flex!,
          alignRight: list[i].alignRight,
        });
      }
    }
  }

  const createSimpleColumns = (index: number, showListIndex?: boolean) => {
    let jsx: JSX.Element[] = [];
    const stop = index + columns!;

    for (let i = index; i < stop; i++) {
      if (i < list.length) {
        if (i === index && firstColumnWidth) {
          console.log(list[i].value);
          jsx.push(
            <Col
              key={'_' + i}
              alignRight={list[i].alignRight}
              width={firstColumnWidth}
            >
              {showListIndex && i === index ? (
                <span>
                  {index / columns!}. {list[i].value}
                </span>
              ) : list[i].value === '' ? (
                <Filler>-</Filler>
              ) : (
                list[i].value
              )}
            </Col>
          );
        } else {
          jsx.push(
            <Col
              flex={list[i].flex}
              key={'_' + i}
              alignRight={list[i].alignRight}
            >
              <div>
                {showListIndex && i === index ? (
                  <span>
                    {index / columns!}. {list[i].value}
                  </span>
                ) : list[i].value === '' ? (
                  <Filler>-</Filler>
                ) : (
                  list[i].value
                )}
              </div>
            </Col>
          );
        }
      }
    }

    return jsx;
  };

  const createColumns = (index: number) => {
    let jsx: JSX.Element[] = [];

    const stop = index + columns!;
    let nrFillers = 0;
    if (index + columns! + 1 > list.length) {
      nrFillers = (index + columns! + 1) % columns!;
    }

    for (let i = index; i < stop; i++) {
      if (i < list.length) {
        if (i === index && firstColumnWidth) {
          jsx.push(
            <ColRow flex={list[i].flex!} key={'_' + i}>
              <Col width={firstColumnWidth} paddingLeft={i === index ? 0 : 10}>
                {list[i].name}
              </Col>
              <Col flex={1}>{list[i].value}</Col>
            </ColRow>
          );
        } else {
          jsx.push(
            <ColRow flex={list[i].flex!} key={'_' + i}>
              <Col flex={2} paddingLeft={i === index ? 0 : 10}>
                {list[i].name}
              </Col>
              <Col flex={3}>{list[i].value}</Col>
            </ColRow>
          );
        }
      }

      if (i < stop - 1) {
        jsx.push(<Gap key={'gap_' + i}> </Gap>);
      }
    }

    for (let i = nrFillers; i > 0; i--) {
      jsx.push(<ColRow flex={list[0 + i].flex!} key={'filler_' + i} />);
    }

    return jsx;
  };

  return (
    <Container marginTop={marginTop}>
      {title && <Title>{title}</Title>}
      {subTitleRow.length > 0 && (
        <Row setBackground={start === 1 ? false : true}>
          {subTitleRow.map((tr, tIndex) => (
            <Col key={tIndex} flex={tr.flex} alignRight={tr.alignRight}>
              {tr.title}
            </Col>
          ))}
        </Row>
      )}
      {(!columns || columns === 1) &&
        !firstColumnWidth &&
        list.map((item, index) => (
          <Row
            setBackground={index % 2 === start}
            key={index}
            usePointer={onClick !== undefined}
            onClick={() => onClick && onClick(item)}
          >
            <Col flex={2} paddingLeft={10}>
              {item.name}
            </Col>
            <Col flex={3}>{item.value}</Col>
          </Row>
        ))}
      {(!columns || columns === 1) &&
        firstColumnWidth &&
        list.map((item, index) => (
          <Row
            setBackground={index % 2 === start}
            key={index}
            usePointer={onClick !== undefined}
            onClick={() => onClick && onClick(item)}
          >
            <Col width={firstColumnWidth} paddingLeft={10}>
              {item.name}
            </Col>
            <Col flex={1}>{item.value}</Col>
          </Row>
        ))}
      {columns &&
        subTitleRow.length === 0 &&
        columns > 1 &&
        list.map((item, index) => {
          if ((index + 1) % columns === 1) {
            return (
              <Row
                setBackground={(index / columns) % 2 === start}
                key={index}
                usePointer={onClick !== undefined}
                onClick={() => onClick && onClick(item)}
              >
                {createColumns(index)}
              </Row>
            );
          }
          return null;
        })}
      {columns &&
        subTitleRow.length > 0 &&
        columns > 1 &&
        list.map((item, index) => {
          if (!item.title) {
            if ((index + 1) % columns === 1) {
              return (
                <Row
                  setBackground={(index / columns) % 2 === start}
                  key={index}
                  usePointer={onClick !== undefined}
                  onClick={() => {
                    if (!!onClick) {
                      onClick(item);
                    }
                  }}
                >
                  {createSimpleColumns(index, showListIndex)}
                </Row>
              );
            }
          }
          return null;
        })}
    </Container>
  );
};

export default ZebraList;
