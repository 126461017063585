export const initialIncidentInfo = {
  title: 'Skada',
  list: [
    { name: 'Skadetyp', value: '' },
    { name: 'Hjälptyp', value: '' },
  ],
};

export const initialCallerContactInformation = {
  title: 'Anmälare',
  list: [
    { name: 'Namn', value: '' },
    { name: 'Telefon', value: '' },
  ],
};

export const initialCarOwnerContactInformation = {
  title: 'Bilägare',
  list: [
    { name: 'Namn', value: '' },
    { name: 'Telefon', value: '' },
    { name: 'Mobil', value: '' },
  ],
};

export const initialCarDriverContactInformation = {
  title: 'Förare',
  list: [
    { name: 'Namn', value: '' },
    { name: 'Telefon', value: '' },
    { name: 'Mobil', value: '' },
  ],
};

export const initialRepairShopInformation = {
  title: 'Verkstadsinformation',
  list: [
    { name: 'Namn', value: '' },
    { name: 'Avlämingsplats', value: '' },
  ],
};

export const initialAssignmentDurationInformation = {
  title: 'Övrigt',
  list: [{ name: 'Anlände - Lämnade', value: '' }],
};

export const initialCarfaxInformation = {
  title: 'Fordon',
  list: [
    { name: 'Antal ägare', value: '' },
    { name: 'Senaste ägarbyte', value: '' },
  ],
};
