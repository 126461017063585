import {
  Bounds,
  CompanyDTO,
  IncidentLocationData,
  IncidentsMapClient,
  IncidentType,
} from '../api-clients/index';
import { GoogleMapsContext } from '../GoogleMaps/GoogleMapsContext';
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { BasicDataContext } from '../../utils/useBasicData';
const { v4: uuidv4 } = require('uuid');

interface ContextProps {
  company?: CompanyDTO;
  loading: boolean;
  startDate: Date;
  setStartDate: (date: Date) => void;
  endDate: Date;
  setEndDate: (date: Date) => void;
  incidentType: IncidentType;
  setIncidentType: (type: IncidentType) => void;
  selectedCompany: CompanyDTO | undefined;
  setSelectedCompany: (company: CompanyDTO | undefined) => void;
  stopListening: () => void;
  setIncidentTypes: (types: string) => void;
  incidentTypes: string;
}

const initialContext: ContextProps = {
  company: undefined,
  loading: false,
  startDate: new Date(Date.now() - 31536000),
  setStartDate: () => Function,
  endDate: new Date(Date.now()),
  setEndDate: () => Function,
  incidentType: IncidentType.GameAccident,
  setIncidentType: () => Function,
  selectedCompany: undefined,
  setSelectedCompany: () => Function,
  stopListening: () => Function,
  incidentTypes: '',
  setIncidentTypes: () => Function,
};

export const IncidentsMapContext = createContext<ContextProps>(initialContext);

const IncidentsMapProvider: React.FC = (props) => {
  const [startDate, setStartDate] = useState<Date>(
    new Date(Date.now() - 2629746000)
  );
  const [endDate, setEndDate] = useState<Date>(new Date(Date.now()));
  const [incidentType, setIncidentType] = useState<IncidentType>(
    IncidentType.None
  );
  const [incidentTypes, setIncidentTypes] = useState<string>('');
  const [company, setCompany] = useState<CompanyDTO | undefined>();
  const [selectedCompany, setSelectedCompany] = useState<
    CompanyDTO | undefined
  >(undefined);

  const {
    scriptLoaded,
    map,
    locations,
    markers,
    addLocations,
    addMarkers,
    clearMarkers,
  } = useContext(GoogleMapsContext);
  const [loading, setLoading] = useState<boolean>(true);

  const { user } = useContext(BasicDataContext);

  useEffect(() => {
    // const _subscription = authService.subscribe(() => {
    //   if (authService._isAuthenticated && loading) {
    getCompany();
    //   }
    // });

    return () => {
      // authService.unsubscribe(_subscription);
    };
  }, []);

  const getCompany = async () => {
    const client = new IncidentsMapClient();
    const response = await client.getCompanies();

    setCompany(response.find((f) => f.id === user?.companyID));
    setLoading(false);
  };

  let timer: NodeJS.Timeout;
  let dragendListener: google.maps.MapsEventListener;
  let dragListener: google.maps.MapsEventListener;
  let zoomListener: google.maps.MapsEventListener;

  var activeKey: any = '';

  const makeAPICall = async () => {
    const key = uuidv4();
    console.log(key);
    activeKey = key;
    ajax(key);
  };
  const ajax = async (key: any) => {
    console.log(key);
    if (map) {
      const client = new IncidentsMapClient();
      const ne = map.getBounds()?.getNorthEast();
      const sw = map.getBounds()?.getSouthWest();

      if (!ne || !sw) {
        console.log('ERROR IN MAP BOUNDS');
      } else {
        const response = await client.getIncidentLocations(
          new IncidentLocationData({
            ne: new Bounds({ lat: ne!.lat(), lng: ne!.lng() }),
            sw: new Bounds({ lat: sw!.lat(), lng: sw!.lng() }),
            assignmentIds: [],
            from: startDate,
            to: endDate,
            // incidentTypeId: incidentType,
            incidentTypes: incidentTypes,
            customerId: company?.assistConceptId,
            insuranceCompanyId: selectedCompany
              ? selectedCompany.assistConceptId
              : undefined,
          })
        );
        if (response && key == activeKey) {
          // addLocations(response);
          clearMarkers();
          addMarkers(response, incidentTypes);
        } else {
          console.log('No response!');
        }
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    if (scriptLoaded && map) {
      dragendListener = google.maps.event.addListener(map, 'dragend', () => {
        timer = setTimeout(() => {
          if (incidentTypes !== '') {
            setLoading(true);
          }
        }, 500);
      });
      dragListener = google.maps.event.addListener(map, 'drag', () => {
        clearTimeout(timer);
      });
      zoomListener = google.maps.event.addListener(map, 'zoom_changed', () => {
        makeAPICall();
      });
    }
    return () => {
      if (scriptLoaded) {
        google.maps.event.removeListener(dragendListener);
        google.maps.event.removeListener(dragListener);
        google.maps.event.removeListener(zoomListener);
      }
    };
  }, [map, scriptLoaded, markers, incidentTypes, loading]);

  useEffect(() => {
    if (incidentTypes !== '') {
      setLoading(true);
    } else {
      clearMarkers();
    }
  }, [incidentTypes, startDate, endDate, selectedCompany]);

  useEffect(() => {
    if (loading) {
      // clearMarkers();
      makeAPICall();
    }
  }, [loading]);

  const stopListening = () => {
    google.maps.event.removeListener(dragendListener);
    google.maps.event.removeListener(dragListener);
    google.maps.event.removeListener(zoomListener);
  };

  return (
    <IncidentsMapContext.Provider
      value={{
        company,
        loading,
        startDate,
        setStartDate: (date: Date) => setStartDate(date),
        endDate,
        setEndDate: (date: Date) => setEndDate(date),
        incidentType,
        setIncidentType: (type: IncidentType) => setIncidentType(type),
        selectedCompany,
        setSelectedCompany: (company: CompanyDTO | undefined) => {
          clearMarkers();
          setSelectedCompany(company);
        },
        stopListening: () => stopListening,
        setIncidentTypes,
        incidentTypes,
      }}
    >
      {props.children}
    </IncidentsMapContext.Provider>
  );
};

export default IncidentsMapProvider;
