import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Colors, FontFamily, FontSize } from '../../utils/styles';
import { useHistory, useParams } from 'react-router-dom';
import { H1 } from '../styled';
import { CaseDetailedActiveTab } from './types';
import InvestigationComponent from './Investigation/InvestigationComponent';
import ProcessingComponent from './Processing/ProcessingComponent';
import CustomerVehicleComponent from './CustomerVehicle/CustomerVehicleComponent';
import RepairShopComponent from './RepairShop/RepairShopComponent';
// import authService from '../../components/api-authorization/AuthorizeService';
import {
  CaseDTO,
  CasesClient,
  FlagEnum,
  ILogMeRequest,
  LogClient,
  LogMeRequest,
  ToggleUserFlagRequest,
} from '../api-clients';
import { CasesContext } from './CasesContext';
import { BasicDataContext, UserRole } from '../../utils/useBasicData';
import dayjs from 'dayjs';

const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-left: 35px;
  padding-top: 40px;
  width: calc(100% - 35px);
`;

const ButtonContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  height: 30px;
  margin: 0;
  padding: 0;
  width: 60%;
  min-width: 550px;
`;

const BackLink = styled.button`
  font-size: ${FontSize.Medium};
  color: ${Colors.Red};
  background-color: transparent;
  outline: none;
  border: none;
  width: 60px;
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
  margin-top: -8px;
  cursor: pointer;
  text-align: left;
  :focus {
    outline: none;
  }
`;

const Button = styled.button<{
  isActive?: boolean;
  rightBorder?: boolean;
}>`
  outline: none;
  border: 1px solid ${Colors.LightGrey};
  border-right: ${(props) =>
    props.rightBorder ? `1px solid ${Colors.LightGrey}` : 'none'};
  font-weight: ${(props) => (props.isActive ? 'bold' : 'normal')};
  font-size: ${FontSize.Medium};
  background-color: transparent;
  padding-top: 3px;
  padding-bottom: 3px;
  width: 100px;
  cursor: ${(props) => (props.isActive ? 'default' : 'pointer')};
  :focus {
    outline: none;
  }
`;

const Border = styled.div`
  width: calc(100% - 35px);
  border-top: 1px solid ${Colors.LightGrey};
  margin-top: -1px;
`;

const IncidentTitle = styled(H1)`
  font-size: 3em;
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 15px;
`;

const CaseDetailed: React.FC = () => {
  console.log(window.location.pathname);
  const param = useParams<Record<string, string | undefined>>();
  const [caseId, setCaseId] = useState<number>(Number(param['id']));
  const [activeTab, setActiveTab] = useState<CaseDetailedActiveTab | undefined>(
    undefined
  );
  const [init, setInit] = useState<boolean>(false);
  const [shellCase, setShellCase] = useState<CaseDTO | null>(null);
  const [role, setRole] = useState<string>('');
  const history = useHistory();

  const { cases, update } = useContext(CasesContext);

  const { user } = useContext(BasicDataContext);

  useEffect(() => {
    if (caseId != Number(param.id)) {
      setCaseId(Number(param.id));
    }
  }, [param]);

  useEffect(() => {
    if (shellCase != null) {
      const logClient = new LogClient();
      const controller = new AbortController();
      const _case = shellCase;

      const logRequest = async (action: string) => {
        if (_case) {
          await logClient.log(
            new LogMeRequest({
              caseId: caseId,
              action: action,
            } as ILogMeRequest),
            controller.signal
          );
        }
      };

      const removeUnreadFlag = () => {
        if (_case) {
          const hasFlag = _case.userFlags?.find(
            (uf) => uf.flagId === FlagEnum.Unread
          );

          if (hasFlag) {
            const client = new CasesClient();
            client.toggleUserFlag(
              new ToggleUserFlagRequest({
                flagType: FlagEnum.Unread,
                caseId: caseId,
              })
            );

            update(caseId, FlagEnum.Unread);
          }
        }
      };

      const initializeCaseDetails = async () => {
        removeUnreadFlag();

        // if (await authService.isAuthenticated()) {
        //   const user = await authService.getUser();
        if (user) {
          if (user.roles!.find((f) => f === UserRole.Investigator)) {
            if (!init) {
              setActiveTab(CaseDetailedActiveTab.INVESTIGATION);
            }
          } else {
            if (!init) setActiveTab(CaseDetailedActiveTab.PROCESSING);
          }

          setRole(user.roles![0]);

          logRequest('Authenticated navigation to case details');
        }
      };

      // const subscription = authService.subscribe(() => {
      //   initializeCaseDetails();
      // });

      initializeCaseDetails();
      setInit(true);
      return () => {
        // authService.unsubscribe(subscription);
        // controller.abort();
      };
    }
  }, [shellCase]);

  useEffect(() => {
    if (shellCase == null || shellCase.id !== caseId) {
      const _getShellCase = async () => {
        const client = new CasesClient();
        var response = await client.getShellCases(caseId);
        setShellCase(response);
      };

      _getShellCase();
    }
  }, [caseId]);

  const updateInvestigationCheck = async (checkValue: boolean | undefined) => {
    const client = new CasesClient();
    var resp = await client.updateInvestigationCheck(
      caseId,
      checkValue == undefined ? 'null' : checkValue.toString()
    );
    var _shellCase = {
      ...shellCase!,
      isInvestigationCase: resp == null ? undefined : resp!,
    } as CaseDTO;

    setShellCase(_shellCase);
  };

  const goBack = () => {
    history.push('/');
  };

  if (activeTab === undefined) return null;
  return (
    <Container>
      <IncidentTitle>
        {shellCase &&
          `${shellCase.registrationNumber}, ${shellCase.incidentType}, ${dayjs(
            shellCase.crashDate!
          ).format('YYYY-MM-DD HH:mm:ss')}`}
      </IncidentTitle>
      <BackLink onClick={() => goBack()}>&#171; Tillbaka</BackLink>
      <ButtonContainer>
        {role !== 'CaseWorker' && (
          <Button
            isActive={activeTab === CaseDetailedActiveTab.INVESTIGATION}
            onClick={
              activeTab === CaseDetailedActiveTab.INVESTIGATION
                ? undefined
                : () => setActiveTab(CaseDetailedActiveTab.INVESTIGATION)
            }
          >
            Utredning
          </Button>
        )}
        {/* <Button
          isActive={activeTab === CaseDetailedActiveTab.PROCESSING}
          onClick={
            activeTab === CaseDetailedActiveTab.PROCESSING
              ? undefined
              : () => setActiveTab(CaseDetailedActiveTab.PROCESSING)
          }
        >
          Handläggning
        </Button> */}
        <Button
          isActive={activeTab === CaseDetailedActiveTab.CUSTOMER_AND_VEHICLE}
          onClick={
            activeTab === CaseDetailedActiveTab.CUSTOMER_AND_VEHICLE
              ? undefined
              : () => setActiveTab(CaseDetailedActiveTab.CUSTOMER_AND_VEHICLE)
          }
        >
          Kund & fordon{' '}
        </Button>
        <Button
          isActive={activeTab === CaseDetailedActiveTab.WORKSHOP_INFO}
          onClick={
            activeTab === CaseDetailedActiveTab.WORKSHOP_INFO
              ? undefined
              : () => setActiveTab(CaseDetailedActiveTab.WORKSHOP_INFO)
          }
          rightBorder={true}
        >
          Verkstadsinfo
        </Button>
      </ButtonContainer>
      <Border />

      {activeTab === CaseDetailedActiveTab.INVESTIGATION &&
        role !== 'CaseWorker' && (
          <InvestigationComponent
            caseId={caseId}
            shellCase={shellCase!}
            updateInvestigationCheck={updateInvestigationCheck}
          />
        )}
      {activeTab === CaseDetailedActiveTab.PROCESSING && (
        <ProcessingComponent caseId={Number(caseId)} />
      )}
      {activeTab === CaseDetailedActiveTab.CUSTOMER_AND_VEHICLE && (
        <CustomerVehicleComponent caseId={caseId} />
      )}
      {activeTab === CaseDetailedActiveTab.WORKSHOP_INFO && (
        <RepairShopComponent caseId={caseId} />
      )}
    </Container>
  );
};

export default CaseDetailed;
