import React, { useState } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Colors, FontSize } from '../../utils/styles';
import { IncidentType } from '../api-clients';
import {
  incidentTypeCol,
  incidentTypeFire,
  incidentTypeGame,
  incidentTypeOther,
  incidentTypeTheft,
} from '../GoogleMaps/utils/GoogleMapsUtils';
import { IncidentsMapContext } from './IncidentsMapContext';

const Wrapper = styled.div`
  display: flex;
  margin-left: 35px;
  align-items: center;
`;

const Title = styled.div`
  font-size: ${FontSize.ZebraTitle};
  font-weight: bold;
`;

const Button = styled.div<{ color: string; isActive: boolean }>`
  font-size: ${FontSize.ZebraTitle};
  padding: 2px 10px;
  border: 1px solid ${Colors.LightGrey};
  border-radius: 3px;
  margin: 0 5px;
  height: 30px;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  align-items: center;

  ${(props) =>
    props.isActive &&
    css`
      background-color: ${props.color};
      color: white;
    `}
`;

const ButtonName = styled.div``;
const ButtonIcon = styled.div`
  margin-right: 10px;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 14px;
    height: 14px;
  }
`;

export const incidentIdMapping = {
  [IncidentType.None]: 'Ingen',
  [IncidentType.EngineFailure]: 'Motorfel',
  [IncidentType.PowerTransmitionFailure]: 'Fel på kraftöverföring',
  [IncidentType.DitchDriving]: 'Dikeskörning',
  [IncidentType.CollisionWithOtherVehicle]: 'Kollision med annat fordon',
  [IncidentType.SingleVehicleAccident]: 'Kollision, singelolycka',
  [IncidentType.GameAccident]: 'Viltolycka',
  [IncidentType.Theft]: 'Stöld',
  [IncidentType.Fire]: 'Brand',
  [IncidentType.Flat]: 'Punktering',
  [IncidentType.GovernmentSalvageTechnical]:
    'Myndighetsbärgning - Teknisk undersökning"',
  [IncidentType.Brakefault]: 'Bromsfel',
  [IncidentType.FaultyAlarm]: 'Larmfel',
  [IncidentType.WheelReplacement]: 'Byte av hjul',
  [IncidentType.GovermentSalvageLongDistance]:
    'Myndighetsbärgning - Lång flytt',
  [IncidentType.GovermentSalvageShortDistance]:
    'Myndighetsbärgning - Kort flytt',
  [IncidentType.NoPower]: 'Strömlös',
  [IncidentType.AttemptedTheft]: 'Stöldförsök',
  [IncidentType.LostWheels]: 'Tappat hjul',
  [IncidentType.FaultyIgnition]: 'Tändlåsfel',
  [IncidentType.LostWheels]: 'Tappat hjul',
  [IncidentType.TransferTransport]: 'Överföringstransport',
  [IncidentType.GovernmentSalvageSeizure]: 'Myndighetsbärgning - Beslag',
  [IncidentType.GovernmentSalvageTBL7]: 'Myndighetsbärgning - TBL 7',
  [IncidentType.Other]: 'Annan',
  [IncidentType.Jumpstart]: 'Tappat hjul',
  [IncidentType.LostKeyOrKeyLockedInside]: 'Inlåst/Borttappad nyckel',
  [IncidentType.ElectricalFailure]: 'Elfel',
  [IncidentType.WrongFuelType]: 'Feltankat',
  [IncidentType.Stuck]: 'Fastkörning',
  [IncidentType.Vandalized]: 'Skadegörelse',
  [IncidentType.FuelShortage]: 'Bränslebrist',
  [IncidentType.Consignment]: 'Framkörning',
  [IncidentType.TransportationRelocation]: 'Trafikverksflytt',
  [IncidentType.MunicipalRelocation]: 'Kommunflytt',
} as { [key: number]: string };

enum ButtonEnums {
  Fire,
  Collision,
  Theft,
  Game,
  Other,
}

const excludedInOther = [
  IncidentType.None,
  IncidentType.Fire,
  IncidentType.SingleVehicleAccident,
  IncidentType.AttemptedTheft,
  IncidentType.Theft,
  IncidentType.GameAccident,
];
const IncidentDict = {
  [ButtonEnums.Fire]: {
    key: 1,
    incidents: [IncidentType.Fire],
    name: incidentIdMapping[IncidentType.Fire],
    icon: incidentTypeFire,
    color: '#d02e25',
  },
  [ButtonEnums.Collision]: {
    key: 2,
    incidents: [IncidentType.SingleVehicleAccident],
    name: 'Kollision',
    icon: incidentTypeCol,
    color: '#e39704',
  },
  [ButtonEnums.Theft]: {
    key: 3,
    incidents: [IncidentType.AttemptedTheft, IncidentType.Theft],
    name: 'Stöld',
    icon: incidentTypeTheft,
    color: '#000000',
  },
  [ButtonEnums.Game]: {
    key: 4,
    incidents: [IncidentType.GameAccident],
    name: 'Viltolycka',
    icon: incidentTypeGame,
    color: '#702407',
  },
  [ButtonEnums.Other]: {
    key: 5,
    incidents: [
      ...Object.keys(IncidentType)
        .map((f) => {
          if (
            !!excludedInOther.find(
              (x) => x === IncidentType[f as keyof typeof IncidentType]
            )
          ) {
            return null;
          }
          return IncidentType[f as keyof typeof IncidentType].valueOf();
        })
        .filter((f) => {
          return f !== null && !isNaN(f) && f !== 0;
        }),
    ],
    name: 'Övrigt',
    icon: incidentTypeOther,
    color: '#676767',
  },
};

const IncidentsButtons: React.FC<{}> = () => {
  const [activeButtons, setActiveButton] = useState<number[]>([]);
  const [activeIncidents, setActiveIncidents] = useState<string>('');

  const { setIncidentType, setIncidentTypes } = useContext(IncidentsMapContext);

  useEffect(() => {
    var incidents = Object.keys(IncidentDict)
      .map((f) => Number(f) as ButtonEnums)
      .reduce<IncidentType[]>((acc, curr: ButtonEnums) => {
        var dict = IncidentDict[curr];
        if (!!activeButtons.find((f) => f === dict.key)) {
          return [...acc, ...(dict.incidents as IncidentType[])];
        }
        return acc;
      }, []);
    console.log(incidents);
    setActiveIncidents(incidents.join(','));
  }, [activeButtons]);

  useEffect(() => {
    console.log(activeIncidents);
    if (activeIncidents != '') {
      setIncidentTypes(activeIncidents);
      setIncidentType(Number(activeIncidents.split(',')[0]));
    } else {
      setIncidentType(IncidentType.None);
      setIncidentTypes('');
    }
  }, [activeIncidents]);

  return (
    <Wrapper>
      <Title>Visa olyckstyp:</Title>
      {Object.keys(IncidentDict).map((_key: string) => {
        var dict = IncidentDict[Number(_key) as ButtonEnums];
        return (
          <Button
            key={dict.key}
            color={dict.color}
            isActive={!!activeButtons.find((f) => f === dict.key)}
            onClick={() => {
              if (!!activeButtons.find((f) => f === dict.key)) {
                setActiveButton(activeButtons.filter((f) => f !== dict.key));
              } else {
                setActiveButton([...activeButtons, dict.key]);
              }
            }}
          >
            <ButtonIcon>
              <img src={dict.icon} />
            </ButtonIcon>
            <ButtonName>{dict.name}</ButtonName>
          </Button>
        );
      })}
    </Wrapper>
  );
};

export default IncidentsButtons;
