import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { GoogleConfigurationClient } from '../api-clients';
import { loadMapApi } from './utils/GoogleMapsUtils';

interface GoogleMapsApiContextProps {
  scriptLoaded: boolean;
  mapsApiKey: string;
}

const initialContext: GoogleMapsApiContextProps = {
  scriptLoaded: false,
  mapsApiKey: '',
};

const GoogleMapsApiContext = createContext<GoogleMapsApiContextProps>(
  initialContext
);

export const useGoogleMapsApi = () => {
  return useContext(GoogleMapsApiContext);
};

const GoogleMapsApiProvider: React.FC = (props) => {
  const [scriptLoaded, setScriptLoaded] = useState<boolean>(false);
  const [mapsApiKey, setMapsApiKey] = useState<string>('');
  // the state change in the useEffect meant we couldn't check the key right after fetching
  // so a ref holds the key, but we expose the state from the context.
  const apiKey = useRef<string>('');

  useEffect(() => {
    const controller = new AbortController();

    const initializeGoogleMapsApi = async () => {
      const client = new GoogleConfigurationClient();
      const response = await client.getGoogleMapsApiKey(controller.signal);
      if (response.apiKey) {
        console.log(response.apiKey);
        apiKey.current = response.apiKey;
        setMapsApiKey(response.apiKey);
      }
    };
    if (apiKey.current === undefined || apiKey.current === '')
      initializeGoogleMapsApi();

    return () => {
      controller.abort();
      apiKey.current = '';
      setMapsApiKey('');
    };
  }, []);
  console.log(apiKey);

  useEffect(() => {
    if (apiKey.current !== undefined && apiKey.current !== '') {
      const googleMapScript = loadMapApi(apiKey.current);
      googleMapScript.addEventListener('load', () => {
        setScriptLoaded(true);
      });

      return () => {
        googleMapScript.removeEventListener('load', () => {
          setScriptLoaded(false);
        });
      };
    }
  }, [apiKey.current]);

  return (
    <GoogleMapsApiContext.Provider
      value={{
        scriptLoaded,
        mapsApiKey,
      }}
    >
      {props.children}
    </GoogleMapsApiContext.Provider>
  );
};

export default GoogleMapsApiProvider;
