export const initialBaseInformation = () => {
  return {
    title: 'Grundläggande information',
    list: [
      { name: 'Uppdragsnummer', value: '' },
      { name: 'Starttid', value: '' },
      { name: 'Källa', value: '' },
    ],
  };
};

export const initialDamageInformation = () => {
  return {
    title: 'Skadeinformation',
    list: [
      { name: 'Skadeorsak', value: '' },
      { name: 'Skadeorsaksbeskrivning', value: '' },
      { name: 'Hjälptyp', value: '' },
      { name: 'Hjälptypsbeskrivning', value: '' },
    ],
  };
};

export const initialLocationInformation = () => {
  return {
    title: '',
    list: [
      { name: 'Gatuadress', value: '' },
      { name: 'Ort', value: '' },
      { name: 'Källa', value: '' },
      { name: 'Longitud', value: '12,9766081' },
      { name: 'Latitud', value: '55,597663' },
      { name: 'Skadeplats', value: '' },
      { name: 'Person på plats, tel.', value: '' },
      { name: 'Person på plats, info.', value: '' },
    ],
  };
};

export const initialTransportInformation = () => {
  return {
    title: 'Transportinformation',
    list: [
      { name: 'Faktisk avlämningsplats', value: '' },
      { name: 'Stad för faktiskt avlämningsplats', value: '' },
    ],
  };
};

export const initialRegisterInformation = () => {
  return {
    title: 'Registreringsinformation',
    list: [
      { name: 'Första registreringsdatum', value: '' },
      { name: 'Vägmätarställning', value: '' },
      { name: 'Avläsningsdatum vägmätarställning', value: '' },
      { name: 'Sista ägarbyte', value: '' },
      { name: 'Antal ägarbyten', value: '' },
    ],
  };
};

export const initialVehicleInformation = () => {
  return {
    title: 'Fordonsinformation',
    columns: 2,
    list: [
      { name: 'Registreringsnummer', value: '', flex: 3 },
      { name: 'Vikt(kg)', value: '', flex: 2 },
      { name: 'Fordonstyp', value: '', flex: 3 },
      { name: 'Färg M', value: '', flex: 2 },
      { name: 'Fordonsbeskrivning', value: '', flex: 3 },
      { name: 'Chassinummer', value: '', flex: 2 },
      { name: 'Har släp/last', value: '', flex: 3 },
      { name: 'Nationalitet', value: '', flex: 2 },
      { name: 'Vikt (kg) släp/last', value: '', flex: 3 },
      { name: 'Mätarställning', value: '', flex: 2 },
      { name: 'Märke', value: '', flex: 3 },
    ],
  };
};

export const initialInsuranceInformation = () => {
  return {
    title: 'Försäkringsinformation',
    list: [
      { name: 'Försäkringsbolag', value: '' },
      { name: 'Vagnskadegarantibolag', value: '' },
      { name: 'Assistanspaket', value: '' },
    ],
  };
};

export const initialOwnerInformation = () => {
  return {
    title: 'Ägarinformation',
    list: [
      { name: 'Namn', value: '' },
      { name: 'Org-/Personnummer', value: '' },
    ],
  };
};
