import dayjs from 'dayjs';
import {
  DrivePathPoint,
  GetCustomerVehicleDetailsResponse,
} from '../../api-clients';
import { ZebraListProps } from '../../ZebraList/ZebraList';
import { ProcessCustomerVehicleDetailsResponseObject } from './types';

export const processCustomerVehicleDetailsResponse = (
  response: GetCustomerVehicleDetailsResponse
): ProcessCustomerVehicleDetailsResponseObject => {
  let baseInformation = {
    title: 'Grundläggande information',
    list: [
      { name: 'Uppdragsnummer', value: `${response.assignmentId ?? ''}` },
      {
        name: 'Starttid',
        value: `${
          dayjs(response.assignmentCreated).format('YYYY-MM-DD HH:mm:ss') ?? ''
        }`,
      },
      { name: 'Källa', value: `${response.caseSource ?? ''}` },
    ],
  } as ZebraListProps;

  let damageInformation = {
    title: 'Skadeinformation',
    list: [
      { name: 'Skadeorsak', value: `${response.incidentType ?? ''}` },
      {
        name: 'Skadeorsaksbeskrivning',
        value: `${response.incidentDescription ?? ''}`,
      },
      { name: 'Hjälptyp', value: `${response.helpType ?? ''}` },
      {
        name: 'Hjälptypsbeskrivning',
        value: `${response.helpTypeDescription ?? ''}`,
      },
    ],
  } as ZebraListProps;

  let locationInformation = {
    title: '',
    list: [
      { name: 'Gatuadress', value: `${response.streetAddress ?? ''}` },
      { name: 'Ort', value: `${response.city ?? ''}` },
      { name: 'Källa', value: `${response.locationType ?? ''}` },
      { name: 'Longitud', value: `${response.longitude ?? ''}` },
      { name: 'Latitud', value: `${response.latitude ?? ''}` },
      { name: 'Skadeplats', value: `${response.locationDescription ?? ''}` },
      {
        name: 'Person på plats, tel.',
        value: `${response.callerContactPhone ?? ''}`,
      },
      {
        name: 'Person på plats, info.',
        value: `${response.callerContactInfo ?? ''}`,
      },
    ],
  } as ZebraListProps;

  let transportInformation = {
    title: 'Transportinformation',
    list: [
      {
        name: 'Faktisk avlämningsplats',
        value: `${response.actualDropOffLocation ?? ''}`,
      },
      {
        name: 'Stad för faktiskt avlämningsplats',
        value: `${response.actualDropOffLocationCity ?? ''}`,
      },
    ],
  } as ZebraListProps;

  let registerInformation = {
    title: 'Registreringsinformation',
    list: [
      {
        name: 'Första registreringsdatum',
        value: response.firstRegistrationDate
          ? `${dayjs(response.firstRegistrationDate)
              .utc()
              .format('YYYY-MM-DD')}`
          : '',
      },
      { name: 'Vägmätarställning', value: `${response.mileage ?? ''}` },
      {
        name: 'Avläsningsdatum vägmätarställning',
        value: response.mileageDate
          ? `${dayjs(response.mileageDate).utc().format('YYYY-MM-DD')}`
          : '',
      },
      {
        name: 'Sista ägarbyte',
        value: response.lastOwnerChangeDate
          ? `${dayjs(response.lastOwnerChangeDate).utc().format('YYYY-MM-DD')}`
          : '',
      },
      { name: 'Antal ägarbyten', value: `${response.legalOwners ?? ''}` },
    ],
  } as ZebraListProps;

  let vehicleInformation = {
    title: 'Fordonsinformation',
    columns: 2,
    list: [
      {
        name: 'Registreringsnummer',
        value: `${response.registrationNumber ?? ''}`,
        flex: 3,
      },
      { name: 'Vikt(kg)', value: `${response.curbWeight ?? ''}`, flex: 2 },
      { name: 'Fordonstyp', value: `${response.vehicleType ?? ''}`, flex: 3 },
      { name: 'Färg M', value: `${response.color ?? ''}`, flex: 2 },
      {
        name: 'Fordonsbeskrivning',
        value: `${response.customVehicleTypeDescription ?? ''}`,
        flex: 3,
      },
      {
        name: 'Chassinummer',
        value: `${response.chassisNumber ?? ''}`,
        flex: 2,
      },
      {
        name: 'Har släp/last',
        value: `${(response.hasCargoOrTrailer ? 'Ja' : 'Nej') ?? ''}`,
        flex: 3,
      },
      {
        name: 'Nationalitet',
        value: `${response.countryOfOrigin ?? ''}`,
        flex: 2,
      },
      {
        name: 'Vikt (kg) släp/last',
        value: `${response.cargoOrTrailerWeight ?? ''}`,
        flex: 3,
      },
      {
        name: 'Mätarställning',
        value: `${response.odometerReading ?? ''}`,
        flex: 2,
      },
      { name: 'Märke', value: `${response.brand ?? ''}`, flex: 3 },
    ],
  } as ZebraListProps;

  let insuranceInformation = {
    title: 'Försäkringsinformation',
    list: [
      {
        name: 'Försäkringsbolag',
        value: `${response.insuranceCompany ?? ''}`,
      },
      {
        name: 'Vagnskadegarantibolag',
        value: `${response.vehicleDamageInsuranceCompany ?? ''}`,
      },
      { name: 'Assistanspaket', value: `${response.assistPackage ?? ''}` },
    ],
  } as ZebraListProps;

  let ownerInformation = {
    title: 'Ägarinformation',
    list: [
      { name: 'Namn', value: `${response.ownerFullName ?? ''}` },
      {
        name: 'Org-/Personnummer',
        value: `${response.orgOrSocialSecurityNumber ?? ''}`,
      },
      { name: 'Telefonnummer', value: `${response.ownerPhone ?? ''}` },
      {
        name: 'Mobiltelefonnummer',
        value: `${response.ownerMobilePhone ?? ''}`,
      },
      { name: 'Adress', value: `${response.ownerStreetAddress ?? ''}` },
      { name: 'Postnummer', value: `${response.ownerZipCode ?? ''}` },
      { name: 'Postort', value: `${response.ownerCity ?? ''}` },
      { name: 'Land', value: `${response.ownerCountry ?? ''}` },
    ],
  };

  return {
    baseInformation: baseInformation,
    damageInformation: damageInformation,
    locationInformation: locationInformation,
    transportInformation: transportInformation,
    registerInformation: registerInformation,
    vehicleInformation: vehicleInformation,
    insuranceInformation: insuranceInformation,
    ownerInformation: ownerInformation,
  };
};

export const processIncidentLocation = (
  response: GetCustomerVehicleDetailsResponse
): DrivePathPoint => {
  return {
    streetAddress: response.streetAddress,
    locationTypeId: response.locationTypeId,
    locationType: response.locationType,
    locationTime: response.locationTime,
    latitude: response.latitude,
    longitude: response.longitude,
  } as DrivePathPoint;
};
