import { GoogleMapsContext } from '../GoogleMaps/GoogleMapsContext';
import Loader from '../Loader';
import React, { useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import IncidentsMapControl from './IncidentsMapControl';
import IncidentsButtons from './IncidentsButtons';
import { IncidentsMapContext } from './IncidentsMapContext';

const ControlContainer = styled.div`
  display: flex;
  z-index: 10;
  width: 100%;
  padding-top: 40px;
  position: relative;
  flex-direction: column;
`;

const MapContainer = styled.div`
  display: flex;
  position: relative;
  z-index: 9;
  width: calc(100% - 70px);
  height: 800px;
  margin-top: 40px;
  margin-left: 35px;
`;

const MapDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const SettingOverlay = styled.div`
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IncidentsContainer: React.FC = ({ children }) => {
  const { scriptLoaded, map, setMap, unloadMap } = useContext(
    GoogleMapsContext
  );
  const { loading } = useContext(IncidentsMapContext);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    return () => {
      unloadMap();
    };
  }, []);

  useEffect(() => {
    if (!map && scriptLoaded) {
      startMap();
    }
  }, [map, scriptLoaded]);

  const startMap = (): void => {
    if (ref.current) {
      setMap(
        new google.maps.Map(ref.current!, {
          zoom: 8,
          center: new google.maps.LatLng(59.326879, 18.070369),
          mapTypeControl: false,
          streetViewControl: false,
          rotateControl: false,
          scaleControl: true,
          fullscreenControl: false,
          panControl: false,
          zoomControl: true,
          scrollwheel: false,
          gestureHandling: 'greedy',
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          draggableCursor: 'pointer',
        })
      );
    }
  };
  return (
    <>
      <ControlContainer>
        <IncidentsMapControl />
        <IncidentsButtons />
        {loading && (
          <SettingOverlay>
            <Loader />
          </SettingOverlay>
        )}
      </ControlContainer>
      <MapContainer>
        {scriptLoaded && <MapDiv ref={ref}>{children}</MapDiv>}
        {(!scriptLoaded || !map) && <Loader center={true} />}
      </MapContainer>
    </>
  );
};

export default IncidentsContainer;
