import {
  CasesClient,
  IncidentType,
  WaystarLocationSearch,
} from '../api-clients/index';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { IMarkerLocation } from './types';
import {
  centerInfoWindow,
  getIncidentMarkerSvg,
  InfoContainer,
  InfoContent,
  loadMapApi,
} from './utils/GoogleMapsUtils';
import { useGoogleMapsApi } from './GoogleMapsApiContext';
import { incidentIdMapping } from '../IncidentsMap/IncidentsButtons';
import ReactDOMServer from 'react-dom/server';
import dayjs from 'dayjs';
import { BasicDataContext } from '../../utils/useBasicData';

interface ContextProps {
  scriptLoaded: boolean;
  map: google.maps.Map | undefined;
  markers: IMarkerLocation[];
  locations: WaystarLocationSearch[];
  setMap: (map: google.maps.Map) => void;
  addMarkers: (
    locations: WaystarLocationSearch[],
    incidentTypes: string
  ) => void;
  addCenterMarker: () => void;
  addLocations: (newLoc: WaystarLocationSearch[]) => void;
  unloadMap: () => void;
  clearMarkers: () => void;
}

const initialContext: ContextProps = {
  scriptLoaded: false,
  map: undefined,
  markers: [],
  locations: [],
  setMap: () => Function,
  addMarkers: () => Function,
  addCenterMarker: () => Function,
  addLocations: () => Function,
  unloadMap: () => Function,
  clearMarkers: () => Function,
};

export const GoogleMapsContext = createContext<ContextProps>(initialContext);

const GoogleMapsProvider: React.FC = (props) => {
  const { scriptLoaded, mapsApiKey } = useGoogleMapsApi();

  const [map, setMap] = useState<google.maps.Map | undefined>(undefined);
  const [locations, setLocations] = useState<WaystarLocationSearch[]>([]);
  const [markers, _setMarkers] = useState<IMarkerLocation[]>([]);
  const [centerMarker, setCenterMarker] = useState<
    google.maps.Marker | undefined
  >(undefined);
  const [icon, setIcon] = useState<google.maps.ReadonlyIcon | undefined>(
    undefined
  );

  const setMarkers = (_markers: IMarkerLocation[]) => {
    if (markers.length > 0) {
      markers.forEach((f) => {
        f.marker.setMap(null);
      });
    }
    _setMarkers(_markers);
  };

  const { user, companies } = useContext(BasicDataContext);
  const [infoWindow, setInfoWindow] = useState<
    google.maps.InfoWindow | undefined
  >(undefined);

  useEffect(() => {
    if (!!mapsApiKey && scriptLoaded) {
      const googleMapScript = loadMapApi(mapsApiKey);
      googleMapScript.addEventListener('load', () => {
        setIcon({
          url:
            'https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi.png',
          scaledSize: new google.maps.Size(15, 33),
          origin: new google.maps.Point(0, 0),
        });
      });

      setInfoWindow(new google.maps.InfoWindow({ disableAutoPan: true }));

      return () => {
        googleMapScript.removeEventListener('load', () => {
          setIcon({
            url:
              'https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi.png',
            scaledSize: new google.maps.Size(15, 33),
            origin: new google.maps.Point(0, 0),
          });
        });
      };
    }
  }, [mapsApiKey, scriptLoaded]);

  useEffect(() => {
    return () => {
      if (infoWindow) google.maps.event.clearInstanceListeners(infoWindow);
      setInfoWindow(undefined);
    };
  }, []);

  const createInfoWindow = async (marker: IMarkerLocation) => {
    if (map && infoWindow) {
      const client = new CasesClient();
      var extraData: {
        caseId?: Number;
        waystarScore?: Number;
        regNumber?: string;
      } = {
        caseId: undefined,
        waystarScore: undefined,
        regNumber: undefined,
      };
      var company = companies?.find((f) => f.id === user?.companyID);
      if (marker.customerId === company?.assistConceptId) {
        var resp = await client.getMapCaseInfo(marker.assignmentId);
        // extraData.regNumber = 'ABC123';
        if (resp) {
          extraData.caseId = resp.caseId;
          extraData.waystarScore = resp.waystarScore;
        }
      }
      infoWindow!.close();
      infoWindow.setContent(renderInfoWindow(marker, extraData));
      infoWindow!.setPosition(
        centerInfoWindow(map, marker.marker.getPosition()!)
      );
      infoWindow!.open(map);
    }
  };

  const renderInfoWindow = (
    marker: IMarkerLocation,
    data: { caseId?: Number; waystarScore?: Number; regNumber?: string }
  ) => {
    return ReactDOMServer.renderToString(
      <InfoContainer>
        {data.regNumber && (
          <InfoContent>Regnummer: {data.regNumber}</InfoContent>
        )}
        {data.waystarScore && (
          <InfoContent>
            WaystarScore: {data.waystarScore.toFixed(2)}
          </InfoContent>
        )}
        {marker.created && (
          <InfoContent>
            Skapad: {dayjs(marker.created).format('YYYY-MM-DD HH:mm:ss')}
          </InfoContent>
        )}
        <InfoContent>
          Skadetyp: {incidentIdMapping[marker.incidentTypeId]}
        </InfoContent>
        {data.caseId && (
          <InfoContent>
            <a href={`/case/${data.caseId!}`} target='_blank'>
              Se uppdraget
            </a>
          </InfoContent>
        )}
      </InfoContainer>
    );
  };

  const addLocations = (newLoc: WaystarLocationSearch[]) => {
    setLocations([...locations, ...newLoc]);
  };

  const unloadMap = () => {
    markers.forEach((marker) => {
      marker.marker.setMap(null);
    });
    setMarkers([]);
    setMap(undefined);
    setCenterMarker(undefined);
  };

  const addCenterMarker = () => {
    if (centerMarker && map) {
      centerMarker.setPosition(map.getCenter());
    } else if (map) {
      setCenterMarker(
        new google.maps.Marker({
          position: map.getCenter(),
          map: map,
        })
      );
    }
  };

  const addMarkers = (
    newLoc: WaystarLocationSearch[],
    incidentTypes: string
  ) => {
    if (map) {
      if (markers.length > 0) {
        // INCIDENTTYPE CHANGED

        markers.forEach((m) => {
          m.marker.setMap(null);
        });

        let newArr: IMarkerLocation[] = [];

        newLoc.forEach((l) => {
          newArr = [
            ...newArr,
            {
              created: l.created,
              customerId: l.customerId,
              assignmentId: l.assignmentId!,
              incidentTypeId: l.incidentTypeId!,
              insuranceCompanyId: l.insuranceCompanyId,
              marker: new google.maps.Marker({
                position: new google.maps.LatLng(l.lat!, l.lng!),
                map: map,
                icon: getIncidentMarkerSvg(l.incidentTypeId! as IncidentType),
              }),
            },
          ];
        });

        const ne = map.getBounds()?.getNorthEast();
        const sw = map.getBounds()?.getSouthWest();

        if (!ne || !sw) {
          console.log('ERROR IN BOUNDS GoogleMapContext');
        } else {
          newArr.forEach((f) => {
            f.marker.addListener('click', () => {
              createInfoWindow(f);
            });
          });

          setMarkers(newArr);
        }
      } else {
        // ADD ALL
        markers.forEach((m) => {
          m.marker.setMap(null);
        });

        const addArr = newLoc.reduce((acc, cur) => {
          return [
            ...acc,
            {
              created: cur.created,
              customerId: cur.customerId,
              assignmentId: cur.assignmentId!,
              incidentTypeId: cur.incidentTypeId!,
              insuranceCompanyId: cur.insuranceCompanyId,
              marker: new google.maps.Marker({
                position: new google.maps.LatLng(cur.lat!, cur.lng!),
                map: map,
                icon: getIncidentMarkerSvg(cur.incidentTypeId as IncidentType),
              }),
            },
          ];
        }, [] as IMarkerLocation[]);

        addArr.forEach((f) => {
          f.marker.addListener('click', () => {
            createInfoWindow(f);
          });
        });
        setMarkers(addArr);
      }
    } else {
      console.log('NO MAP INITIALIZED!!!!');
    }
  };

  const clearMarkers = () => {
    if (scriptLoaded) {
      markers.forEach((f: IMarkerLocation) => {
        f.marker.setMap(null);
      });
      setLocations([]);
      setMarkers([]);
    }
  };

  return (
    <GoogleMapsContext.Provider
      value={{
        scriptLoaded,
        map,
        markers,
        locations,
        addLocations: (newLoc: WaystarLocationSearch[]) => addLocations(newLoc),
        setMap: (map: google.maps.Map) => setMap(map),
        addMarkers: (
          locations: WaystarLocationSearch[],
          incidentTypes: string
        ) => addMarkers(locations, incidentTypes),
        addCenterMarker: addCenterMarker,
        unloadMap: unloadMap,
        clearMarkers,
      }}
    >
      {props.children}
    </GoogleMapsContext.Provider>
  );
};

export default GoogleMapsProvider;
