import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  FirstColumnWidth,
  FontFamily,
  FontSize,
  Margins,
} from '../../../utils/styles';
import {
  CasesClient,
  DrivePathPoint,
  GetCustomerVehicleDetailsResponse,
} from '../../api-clients';
import { GoogleMapsContext } from '../../GoogleMaps/GoogleMapsContext';
import Map from '../../GoogleMaps/Map';
import { getReportLocationMarkerSvg } from '../../GoogleMaps/utils/GoogleMapsUtils';
import { H1 } from '../../styled';
import ZebraList, { ZebraListProps } from '../../ZebraList/ZebraList';
import { Container, Divider, SubContainer } from '../styles';
import {
  initialBaseInformation,
  initialDamageInformation,
  initialInsuranceInformation,
  initialLocationInformation,
  initialOwnerInformation,
  initialRegisterInformation,
  initialTransportInformation,
  initialVehicleInformation,
} from './emptyData';

import dayjs from 'dayjs';
import dayJsUtcModule from 'dayjs/plugin/utc';
import Loader from '../../Loader';
import {
  processCustomerVehicleDetailsResponse,
  processIncidentLocation,
} from './utils';
dayjs.extend(dayJsUtcModule);

const CustomerVehicleContainer = styled(Container)`
  padding-bottom: 60px;
`;

const Title = styled(H1)`
  margin-top: ${Margins.Double};
  margin-bottom: ${Margins.Small};
`;

const SpaceDivider = styled(Divider)`
  margin-bottom: 5px;
`;

const SubTitle = styled.h5`
  font-weight: bold;
  font-size: ${FontSize.ZebraTitle};
  text-transform: uppercase;
  margin-top: ${Margins.Double};
  margin-bottom: ${Margins.Small};
  letter-spacing: 0.5px;
`;

const MapContainer = styled.div`
  width: calc(100% - ${Margins.Small});
  height: 500px;
  min-height: 550px;
  /* min-width: 550px; */
`;

interface Props {
  caseId: number;
}

const CustomerVehicleComponent: React.FC<Props> = ({ caseId }) => {
  const { scriptLoaded } = useContext(GoogleMapsContext);

  const [data, setData] = useState<
    GetCustomerVehicleDetailsResponse | undefined
  >(undefined);

  const baseInformation = useRef<ZebraListProps>(initialBaseInformation());
  const damageInformation = useRef<ZebraListProps>(initialDamageInformation());
  const locationInformation = useRef<ZebraListProps>(
    initialLocationInformation()
  );
  const transportInformation = useRef<ZebraListProps>(
    initialTransportInformation()
  );
  const registerInformation = useRef<ZebraListProps>(
    initialRegisterInformation()
  );
  const vehicleInformation = useRef<ZebraListProps>(
    initialVehicleInformation()
  );
  const insuranceInformation = useRef<ZebraListProps>(
    initialInsuranceInformation()
  );
  const ownerInformation = useRef<ZebraListProps>(initialOwnerInformation());

  const [incidentLocation, setIncidentLocation] = useState<
    DrivePathPoint | undefined
  >(undefined);

  useEffect(() => {
    const getCustomerVehicleDetails = async (controller: AbortController) => {
      const client = new CasesClient();
      client
        .getCustomerVehicleDetails(caseId, controller.signal)
        .then((response) => {
          const processResponseObj = processCustomerVehicleDetailsResponse(
            response
          );
          baseInformation.current = processResponseObj.baseInformation;
          damageInformation.current = processResponseObj.damageInformation;
          locationInformation.current = processResponseObj.locationInformation;
          transportInformation.current =
            processResponseObj.transportInformation;
          registerInformation.current = processResponseObj.registerInformation;
          vehicleInformation.current = processResponseObj.vehicleInformation;
          insuranceInformation.current =
            processResponseObj.insuranceInformation;
          ownerInformation.current = processResponseObj.ownerInformation;
          setIncidentLocation(processIncidentLocation(response));
          setData(response);
        })
        .catch((error) => console.log(error));
    };

    const controller = new AbortController();

    resetData();
    getCustomerVehicleDetails(controller);

    return () => controller.abort();
  }, [caseId]);

  const resetData = () => {
    baseInformation.current = initialBaseInformation();
    damageInformation.current = initialDamageInformation();
    locationInformation.current = initialLocationInformation();
    transportInformation.current = initialTransportInformation();
    registerInformation.current = initialRegisterInformation();
    vehicleInformation.current = initialVehicleInformation();
    insuranceInformation.current = initialInsuranceInformation();
    ownerInformation.current = initialOwnerInformation();
  };

  return (
    <CustomerVehicleContainer>
      {data === undefined && <Loader center={true} />}
      {data && (
        <>
          <SubContainer>
            <Title>Skada/Plats</Title>
            <SpaceDivider />
            <ZebraList
              title={baseInformation.current.title}
              list={baseInformation.current.list}
              firstColumnWidth={FirstColumnWidth.Default}
            />
            <ZebraList
              marginTop={20}
              title={damageInformation.current.title}
              list={damageInformation.current.list}
              firstColumnWidth={FirstColumnWidth.Default}
            />
            {incidentLocation !== undefined &&
              incidentLocation.latitude != null &&
              incidentLocation.longitude != null && (
                <>
                  <SubTitle>Platsinformation</SubTitle>
                  <MapContainer>
                    {scriptLoaded && (
                      <Map
                        zoom={9}
                        scrollwheel
                        center={
                          new google.maps.LatLng(
                            incidentLocation.latitude,
                            incidentLocation.longitude
                          )
                        }
                        markers={{
                          [incidentLocation.reportLocationId!]: {
                            marker: new google.maps.Marker({
                              position: new google.maps.LatLng(
                                incidentLocation.latitude,
                                incidentLocation.longitude
                              ),
                              zIndex: 0,
                              optimized: false,
                              icon: getReportLocationMarkerSvg(
                                incidentLocation.locationTypeId!,
                                incidentLocation.isLocationRepairShop
                              ),
                            }),
                          },
                        }}
                      />
                    )}
                  </MapContainer>
                </>
              )}
            <ZebraList
              marginTop={20}
              title={locationInformation.current.title}
              startWithWhite={true}
              list={locationInformation.current.list}
              firstColumnWidth={FirstColumnWidth.Default}
            />
            <ZebraList
              marginTop={20}
              title={transportInformation.current.title}
              list={transportInformation.current.list}
              firstColumnWidth={FirstColumnWidth.Default}
            />
            <Title>Fordon</Title>
            <Divider />
            <ZebraList
              marginTop={10}
              title={registerInformation.current.title}
              list={registerInformation.current.list}
              firstColumnWidth={FirstColumnWidth.Default}
            />
            <ZebraList
              marginTop={20}
              title={vehicleInformation.current.title}
              list={vehicleInformation.current.list}
              firstColumnWidth={FirstColumnWidth.Default}
              columns={2}
            />
            <ZebraList
              marginTop={20}
              title={insuranceInformation.current.title}
              list={insuranceInformation.current.list}
              firstColumnWidth={FirstColumnWidth.Default}
            />
            <ZebraList
              marginTop={20}
              title={ownerInformation.current.title}
              list={ownerInformation.current.list}
              firstColumnWidth={FirstColumnWidth.Default}
            />
          </SubContainer>
        </>
      )}
    </CustomerVehicleContainer>
  );
};

export default CustomerVehicleComponent;
