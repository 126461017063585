import React, { useContext } from 'react';
import styled from 'styled-components';
import { Colors, FontFamily } from '../../utils/styles';
import { CasesFilter } from '../api-clients';
import { CasesContext } from './CasesContext';

const Option = styled.div`
  user-select: none;
  font-weight: normal;
  cursor: pointer;
  color: ${Colors.Red};
`;

const Selected = styled.div`
  user-select: none;
  font-weight: bold;
  color: ${Colors.Black};
`;

const Separator = styled.div`
  user-select: none;
  font-weight: normal;
  padding-left: 8px;
  padding-right: 8px;
  color: ${Colors.Black};
`;

const MarginRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Row = styled.div`
  display: inline-flex;
  flex-direction: row;
`;

const filterNames = ['Alla', 'WayStar Score', 'Favoriter', 'Olästa', 'Tid'];

const CaseFilterComponent: React.FC = () => {
  const {
    getCurrentFilter,
    numCases,
    waystarScore,
    favored,
    unread,
    setCurrentFilter,
  } = useContext(CasesContext);
  const filterList = Object.values(CasesFilter).filter(
    (f) => typeof f === 'number' && f !== 3
  );
  const num = filterList.length - 1;
  return (
    <MarginRow>
      <Row>
        {filterList.map((filter, index: number) => {
          return (
            <Row key={index}>
              {getCurrentFilter() === filter ? (
                <Selected>{`${filterNames[filter]}`}</Selected>
              ) : (
                <Option
                  onClick={() => {
                    setCurrentFilter(filter as CasesFilter);
                  }}
                >
                  {`${filterNames[filter as CasesFilter]}`}
                </Option>
              )}
              {index < num && <Separator>{` | `}</Separator>}
            </Row>
          );
        })}
      </Row>
    </MarginRow>
  );
};

export default CaseFilterComponent;
