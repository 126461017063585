import {
  CaseDTO,
  CasesClient,
  CasesFilter,
  CasesOrderBy,
  CasesSort,
  FlagEnum,
  GetCasesResponse,
  UserFlag,
} from '../api-clients/index';
import React, { createContext, useEffect, useRef, useState } from 'react';
import { CasesContextSettings } from './types';

export interface ContextProps {
  cases: CaseDTO[];
  loading: boolean;
  numCases: number;
  filteredNumber: number;
  waystarScore: number;
  favored: number;
  unread: number;
  setTo: (to: Date) => void;
  setFrom: (from: Date) => void;
  setPageIndex: (index: number) => void;
  setCurrentFilter: (filter: CasesFilter) => void;
  setCurrentSort: (by: CasesSort) => void;
  setSearchInput: (input: string) => void;
  getCases: (repairShopsForCaseId?: number) => void;
  getTo: () => Date;
  getFrom: () => Date;
  getPageIndex: () => number;
  getPageSize: () => number;
  getCurrentFilter: () => CasesFilter;
  getCurrentSort: () => CasesSort;
  getCurrentOrderBy: () => CasesOrderBy;
  getSearchInput: () => string;
  update: (caseId: number, type: FlagEnum) => void;
  setLoading: (loading: boolean) => void;
  setCurrentOrderBy: (orderBy: CasesOrderBy) => void;
  saveSettings: () => void;
  loadSettings: () => void;
  setDefaultSettings: () => void;
}

export const initialContext: ContextProps = {
  cases: [],
  loading: true,
  numCases: 0,
  filteredNumber: 0,
  waystarScore: 0,
  favored: 0,
  unread: 0,
  getSearchInput: () => '',
  setTo: () => Function,
  setFrom: () => Function,
  setPageIndex: () => Function,
  setCurrentFilter: () => Function,
  setCurrentSort: () => Function,
  setSearchInput: () => Function,
  getCases: (repairShopsForCaseId?: number) => Function,
  getTo: () => new Date(),
  getFrom: () => new Date(),
  getPageIndex: () => 0,
  getPageSize: () => 0,
  getCurrentFilter: () => CasesFilter.ALL,
  getCurrentOrderBy: () => CasesOrderBy.CREATED,
  getCurrentSort: () => CasesSort.DESCENDING,
  update: () => Function,
  setLoading: () => Function,
  setCurrentOrderBy: () => Function,
  saveSettings: () => Function,
  loadSettings: () => Function,
  setDefaultSettings: () => Function,
};

export const CasesContext = createContext<ContextProps>(initialContext);

const CasesProvider: React.FC = (props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [cases, setCases] = useState<CaseDTO[]>([]);

  const [numCases, setNumCases] = useState<number>(0);
  const [filteredNumber, setFilteredNumber] = useState<number>(0);
  const [waystarScore, setWaystarScore] = useState<number>(0);
  const [favored, setFavored] = useState<number>(0);
  const [unread, setUnread] = useState<number>(0);
  const [fetching, setFetching] = useState<boolean>(true);

  const fromInput = useRef<Date>(
    new Date(new Date().setFullYear(new Date().getFullYear() - 1))
  );
  const toInput = useRef<Date>(new Date());
  const pageIndex = useRef<number>(0);
  const pageSize = useRef<number>(50);
  const currentFilter = useRef<CasesFilter>(CasesFilter.ALL);
  const currentSort = useRef<CasesSort>(CasesSort.DESCENDING);
  const currentOrderBy = useRef<CasesOrderBy>(CasesOrderBy.CREATED);
  const searchInput = useRef<string>('');
  // const triggerGetCases = useRef<boolean>(true);

  const setTriggerGetCases = (newTriggerGetCases: boolean) => {
    setFetching(true);
  };

  const setSearchInput = (newSearchInput: string, trigger = true) => {
    searchInput.current = newSearchInput;
    setPageIndex(0, false);

    if (trigger) {
      setTriggerGetCases(true);
    }
  };

  const setTo = (to: Date, trigger = true) => {
    toInput.current = to;
    setPageIndex(0, false);

    if (trigger) {
      setTriggerGetCases(true);
    }
  };

  const setFrom = (from: Date, trigger = true) => {
    fromInput.current = from;
    setPageIndex(0, false);

    if (trigger) {
      setTriggerGetCases(true);
    }
  };

  const setPageIndex = (newPageIndex: number, trigger = true) => {
    pageIndex.current = newPageIndex;

    if (trigger) {
      setTriggerGetCases(true);
    }
  };

  const setPageSize = (newPageSize: number, trigger = true) => {
    pageSize.current = newPageSize;

    if (trigger) {
      setTriggerGetCases(true);
    }
  };

  const setCurrentFilter = (newFilter: CasesFilter, trigger = true) => {
    currentFilter.current = newFilter;
    setPageIndex(0, false);

    if (trigger) {
      setTriggerGetCases(true);
    }
  };

  const setCurrentSort = (newSort: CasesSort, trigger = true) => {
    currentSort.current = newSort;

    if (trigger) {
      setTriggerGetCases(true);
    }
  };

  const setCurrentOrderBy = (newOrderBy: CasesOrderBy, trigger = true) => {
    currentOrderBy.current = newOrderBy;

    if (trigger) {
      setTriggerGetCases(true);
    }
  };

  const getSearchInput = (): string => {
    return searchInput.current;
  };

  const getTo = (): Date => {
    return toInput.current;
  };

  const getFrom = (): Date => {
    return fromInput.current;
  };

  const getPageIndex = (): number => {
    return pageIndex.current;
  };

  const getPageSize = (): number => {
    return pageSize.current;
  };

  const getCurrentFilter = (): CasesFilter => {
    return currentFilter.current;
  };

  const getCurrentSort = (): CasesSort => {
    return currentSort.current;
  };

  const getCurrentOrderBy = (): CasesOrderBy => {
    return currentOrderBy.current;
  };

  const isInRepairShop = useRef<number | undefined>(undefined);

  const settings = useRef<CasesContextSettings | undefined>(undefined);

  const loadSettings = () => {
    console.log(settings.current);
    if (
      isInRepairShop.current !== undefined &&
      settings.current !== undefined
    ) {
      isInRepairShop.current = undefined;
      console.log('Filtre', settings.current.filter);
      if (settings.current !== undefined) {
        setCurrentFilter(settings.current.filter, false);
        setCurrentSort(settings.current.sort, false);
        setCurrentOrderBy(settings.current.orderBy, false);
        setSearchInput(settings.current.searchInput, false);
        setFrom(settings.current.from, false);
        setTo(settings.current.to, false);
        setPageIndex(settings.current.pageIndex, false);
        setPageSize(settings.current.pageSize, false);
        settings.current = undefined;

        getCases();
      }
    }
  };

  const saveSettings = () => {
    settings.current = {
      pageIndex: pageIndex.current,
      pageSize: pageSize.current,
      filter: currentFilter.current,
      sort: currentSort.current,
      orderBy: currentOrderBy.current,
      searchInput: searchInput.current,
      from: fromInput.current,
      to: toInput.current,
    };
    isInRepairShop.current = undefined;
  };

  const setDefaultSettings = () => {
    setPageIndex(0, false);
    setPageSize(50, false);
    setCurrentFilter(CasesFilter.ALL, false);
    setCurrentSort(CasesSort.DESCENDING, false);
    setCurrentOrderBy(CasesOrderBy.CREATED, false);
    setSearchInput('', false);
    setFrom(
      new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
      false
    );
    setTo(new Date(), false);
  };

  const getCases = async (repairShopsForCaseId?: number) => {
    setLoading(true);
    setFetching(false);
    console.log('getCases');
    let response: GetCasesResponse;
    const client = new CasesClient();
    if (
      repairShopsForCaseId !== undefined ||
      isInRepairShop.current !== undefined
    ) {
      if (repairShopsForCaseId !== undefined) {
        isInRepairShop.current = repairShopsForCaseId!;
      }

      response = await client.getRepairshopCases(
        true,
        pageIndex.current,
        pageSize.current,
        fromInput.current,
        toInput.current,
        currentFilter.current,
        currentSort.current,
        currentOrderBy.current,
        searchInput.current,
        isInRepairShop.current
      );
    } else {
      response = await client.getCases(
        true,
        pageIndex.current,
        pageSize.current,
        fromInput.current,
        toInput.current,
        currentFilter.current,
        currentSort.current,
        currentOrderBy.current,
        searchInput.current
      );
    }

    if (response) {
      if (response.numberOfCases !== undefined) {
        setNumCases(response.numberOfCases);
      }

      if (response.waystarScore !== undefined) {
        setWaystarScore(response.waystarScore);
      }

      if (response.favored !== undefined) {
        setFavored(response.favored);
      }

      if (response.unread !== undefined) {
        setUnread(response.unread);
      }

      if (response.filteredNumber !== undefined) {
        setFilteredNumber(response.filteredNumber);
      }

      setCases(response.cases!);
    }

    setLoading(false);
  };

  const update = (caseId: number, type: FlagEnum) => {
    let remove: boolean | undefined;

    setCases(
      cases.map((c) => {
        if (c.id === caseId) {
          if (c.userFlags) {
            if (c.userFlags.find((uf) => uf.flagId === type)) {
              c.userFlags = c.userFlags.filter((uf) => !(uf.flagId === type));
              remove = true;
            } else {
              if (type !== FlagEnum.Unread) {
                c.userFlags.push({
                  flagId: type,
                  caseId: c.id,
                  id: -1,
                  created: new Date(),
                  updated: new Date(),
                } as UserFlag);
              }

              remove = false;
            }
          }
        }

        return c;
      })
    );

    switch (type) {
      case FlagEnum.Starred:
        if (remove === true) {
          setFavored(favored - 1);
        } else if (remove === false) {
          setFavored(favored + 1);
        }
        break;

      case FlagEnum.Unread:
        if (remove === true) {
          setUnread(unread - 1);
        }
        break;
    }
  };

  useEffect(() => {
    saveSettings();
  }, []);

  useEffect(() => {
    console.log('loading useeffect');
    if (fetching) {
      getCases();
    }
  }, [fetching]);

  return (
    <CasesContext.Provider
      value={{
        getPageIndex: (): number => getPageIndex(),
        getPageSize: (): number => getPageSize(),
        cases,
        getCurrentSort: (): CasesSort => getCurrentSort(),
        getCurrentOrderBy: (): CasesOrderBy => getCurrentOrderBy(),
        loading,
        getCurrentFilter: (): CasesFilter => getCurrentFilter(),
        favored,
        unread,
        numCases,
        filteredNumber,
        waystarScore,
        getSearchInput: (): string => getSearchInput(),
        getTo: (): Date => getTo(),
        getFrom: (): Date => getFrom(),
        setTo: (to: Date) => setTo(to),
        setFrom: (from: Date) => setFrom(from),
        setPageIndex: (index: number) => setPageIndex(index),
        setCurrentFilter: (filter: CasesFilter) => setCurrentFilter(filter),
        setCurrentSort: (sort: CasesSort) => setCurrentSort(sort),
        setSearchInput: (input: string) => setSearchInput(input),
        getCases: (repairShopsForCaseId?: number) =>
          getCases(repairShopsForCaseId),
        update: (caseId: number, type: FlagEnum) => update(caseId, type),
        setLoading: (loading: boolean) => setLoading(loading),
        setCurrentOrderBy: (orderBy: CasesOrderBy) =>
          setCurrentOrderBy(orderBy),
        saveSettings: () => saveSettings(),
        loadSettings: () => loadSettings(),
        setDefaultSettings: () => setDefaultSettings(),
      }}
    >
      {props.children}
    </CasesContext.Provider>
  );
};

export default CasesProvider;
