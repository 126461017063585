import React from 'react';
import { useContext } from 'react';
import styled from 'styled-components';
import { Colors } from '../../utils/styles';
import { ModalStackContext } from '../Modal/useModalStack';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextBox = styled.div`
  margin-bottom: 30px;
  background: white;
  border-radius: 10px;
  padding: 60px;
  border: 2px solid ${Colors.DividerGrey};
  font-size: 20px;
  font-weight: bold;
`;

interface Props {
  popup?: boolean;
}

const NoLicense: React.FC<Props> = (props) => {
  const { popup = false } = props;
  const text = (
    <span>
      Innehållet är tillfälligt avstängt. Vänligen kontakta{' '}
      <a href='mailto:info@waystar.ai'>info@waystar.ai</a> för mer information.
    </span>
  );

  if (popup) {
    return <TextBox>{text}</TextBox>;
  }

  return (
    <Wrapper>
      <TextBox>{text}</TextBox>
    </Wrapper>
  );
};

export default NoLicense;
