import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Loader from '../Loader';
import ImageModal from '../Modal/ImageModal';

const RootContainer = styled.div`
  box-sizing: border-box;
`;

const CarouselContainer = styled.div`
  box-sizing: inherit;
  height: 100%;
`;

const SpinnerContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-content: center;
  position: relative;
  &.centered {
    width: 450px;
    height: 470px;
  }
  &.modal {
    height: calc(100vh - 300px);
    width: 470px;
  }
`;

const StyledImg = styled.img`
  object-fit: contain;
  height: 100%;
  cursor: pointer;
  box-sizing: inherit;

  &.modal-image {
    max-height: calc(100vh - 300px);
  }

  &.thumbnail-image {
    scroll-snap-align: start;
    scroll-snap-stop: always;
  }
`;

const LargeModalImageContainer = styled.div`
  margin-bottom: 20px;
`;

const LargeImageContainer = styled(LargeModalImageContainer)`
  height: 470px;
  box-sizing: inherit;
`;

const ThumbContainer = styled.div`
  display: flex;
`;

const ThumbsWrapper = styled.div`
  display: inline-flex;
  height: 125px;
  width: 100%;
  &.modal {
    max-width: 600px;
  }
  scroll-snap-type: x mandatory;
  white-space: nowrap;
  box-sizing: inherit;
  overflow-x: auto;
`;

const ThumbnailWrapper = styled.div`
  display: flex;
  height: inherit;
  box-sizing: inherit;
`;

const ThumbRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100px;
  box-sizing: inherit;
  ${ThumbnailWrapper}:not(:last-child) {
    margin-right: 20px;
  }
`;

interface Props {
  ids: number[];
}

const ImageCarousel: React.FC<Props> = ({ ids }) => {
  const [mainIndex, setMainIndex] = useState<number>(0);
  const [modalIndex, setModalIndex] = useState<number>(0);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [thumbnailsLoaded, setThumbnailsLoaded] = useState<number>(0);
  let scrollRef = useRef<HTMLDivElement>(null);

  const thumbSrc = (id = '') => `/case/Thumbnail/${id}`;

  // HACK: the thumbnails are loaded twice, the first being for the modal and
  // the second time for the row under the main image in the investigation view.
  // This second row needs to fully load before we adjust the scrollbar.
  useEffect(() => {
    if (thumbnailsLoaded === ids.length * 2) {
      resetHorizontalScroll();
    }
  }, [thumbnailsLoaded, ids]);

  const resetHorizontalScroll = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft = 0;
    }
  };

  const syncThumbs = (index: number) => {
    setMainIndex(index);
  };

  const [thumbnails] = useState(
    ids.map((id) => (
      <LazyImage
        key={id.toString()}
        className={'thumbnail-image'}
        alt={'Bild tagen av bärgare på fordon vid skadeplats'}
        src={thumbSrc(id.toString())}
        handleThumbnailLoaded={() => {
          setThumbnailsLoaded((loaded) => (loaded += 1));
        }}
      />
    ))
  );

  return (
    <RootContainer>
      <ImageModal showModal={showModal} closeModal={() => setShowModal(false)}>
        <LargeModalImageContainer>
          <LazyImage
            className={'modal-image'}
            spinnerClassName={'modal'}
            src={`/Case/Image/${ids[modalIndex]}`}
            alt={'Bild tagen av bärgare på fordon vid skadeplats'}
          />
        </LargeModalImageContainer>
        <ThumbsWrapper className={'modal'}>
          <ThumbRow>
            {thumbnails.map((imageComponent, index) => (
              <ThumbnailWrapper
                key={ids[index].toString()}
                onClick={() => setModalIndex(index)}
              >
                {imageComponent}
              </ThumbnailWrapper>
            ))}
          </ThumbRow>
        </ThumbsWrapper>
      </ImageModal>
      <CarouselContainer>
        <LargeImageContainer>
          <LazyImage
            src={thumbSrc(ids[mainIndex].toString())}
            alt={'Bild tagen av bärgare på fordon vid skadeplats'}
            spinnerClassName={'centered'}
            onClick={() => {
              setShowModal((prevState) => !prevState);
              setModalIndex(mainIndex);
            }}
          />
        </LargeImageContainer>
        <ThumbContainer>
          <ThumbsWrapper ref={scrollRef}>
            <ThumbRow>
              {thumbnails.map((imageComponent, index) => (
                <ThumbnailWrapper
                  key={ids[index].toString()}
                  onClick={() => syncThumbs(index)}
                >
                  {imageComponent}
                </ThumbnailWrapper>
              ))}
            </ThumbRow>
          </ThumbsWrapper>
        </ThumbContainer>
      </CarouselContainer>
    </RootContainer>
  );
};

type LazyImageProps = {
  className?: string;
  spinnerClassName?: string;
  centerLoadingSpinner?: boolean;
  src: string;
  alt: string;
  onClick?: () => void;
  handleThumbnailLoaded?: () => void;
};

const LazyImage = ({
  src,
  alt,
  className,
  spinnerClassName,
  onClick,
  handleThumbnailLoaded,
}: LazyImageProps) => {
  const [isLoading, setLoading] = useState(true);
  const [imageSrc, setImageSrc] = useState(src);

  const imgRef = useRef<HTMLImageElement | undefined>(undefined);
  const seenImages = useRef<string[]>([src]);

  function loadImage(imgsrc: string) {
    if (imgRef.current) {
      imgRef.current.src = imgsrc;
      imgRef.current.onload = () => {
        setLoading(false);
        if (className === 'thumbnail-image') {
          handleThumbnailLoaded && handleThumbnailLoaded();
        }
      };
      imgRef.current.onerror = () => {
        setLoading(false);
      };
    }
  }

  useEffect(() => {
    imgRef.current = new Image();
    loadImage(imageSrc);

    return () => {
      seenImages.current = [];
      if (!imgRef.current) {
        return;
      } else {
        imgRef.current.onload = function () {};
        imgRef.current = undefined;
      }
    };
  }, []);

  useEffect(() => {
    // NOTE: If the image component should load some other image than the image it was first assigned to
    // Also track the ones we have already seen once so that the loading spinner isnt showing for those
    // times the image can be instantly loaded from disk/memory cache (preventing spinner flicker)
    if (imageSrc !== src) {
      if (seenImages.current!.indexOf(src) === -1) {
        setLoading(true);
        seenImages.current = seenImages.current!.concat(src);
      }
      setImageSrc(src);
      loadImage(src);
    }
  }, [imageSrc, src]);

  return isLoading ? (
    <SpinnerContainer className={spinnerClassName}>
      <Loader />
    </SpinnerContainer>
  ) : (
    <StyledImg
      className={className}
      src={imageSrc}
      alt={alt}
      onClick={onClick}
    />
  );
};

export default ImageCarousel;
