import React, { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { CarfaxResponse, CasesClient } from '../../api-clients';
import styled from 'styled-components';
import Loader from '../../Loader';
import DoesNotExists from '../../DoesNotExists';
import './report.css';

interface Props {}

const FrameReport = styled.embed`
  width: 100%;
  border: 0;
`;

const FrameWrapper = styled.div`
  height: 800px;
  overflow-y: hidden;
  width: 100%;
`;

const Wrapper = styled.div`
  padding: 40px 35px 20px;
  position: relative;
`;

const HtmlWrapper = styled.div`
  padding: 20px 0;
  background-color: #f2f2f2;
`;
const CarfaxReport: React.FC<Props> = () => {
  const param = useParams<Record<string, string | undefined>>();
  const [caseId, setCaseId] = useState<number>(Number(param['id']));
  const [report, setReport] = useState<CarfaxResponse | undefined>(undefined);

  useEffect(() => {
    fetchReport();
  }, []);

  useEffect(() => {
    if (report?.success) {
      setTimeout(() => {
        filterReport();
      }, 0);
    }
  }, [report]);

  const fetchReport = async () => {
    var client = new CasesClient();
    var resp = await client.getCarfaxReport(caseId);
    setReport(resp);
  };

  if (report === undefined) {
    return <Loader />;
  }

  const filterReport = () => {
    var uls = document.getElementsByTagName(
      'ul'
    ) as HTMLCollectionOf<HTMLUListElement>;
    for (var i = 0; i < uls.length; i++) {
      var ul = uls[i] as HTMLUListElement;
      if (ul.className === 'menu') {
        var parent = ul.parentElement;
        if (!!parent) {
          parent!.innerHTML = '';
        }
      }
    }
  };

  return (
    <Wrapper>
      {!report.success && (
        <>
          <DoesNotExists />
          <FrameWrapper>
            <FrameReport src={`${report}#toolbar=0&navpanes=0`} />
          </FrameWrapper>
        </>
      )}
      {report.success && (
        <HtmlWrapper dangerouslySetInnerHTML={{ __html: report.htmlData! }} />
      )}
    </Wrapper>
  );
};

export default CarfaxReport;
