import { IncidentType } from '../api-clients/index';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { Colors, FontFamily, FontSize } from '../../utils/styles';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './IncidentsMap.css';
import { IncidentsMapContext } from './IncidentsMapContext';
import Loader from '../Loader';
import { BasicDataContext } from '../../utils/useBasicData';

const Container = styled.div`
  display: flex;
  background-color: white;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  align-items: center;
`;

const Overlay = styled.div`
  height: 80px;
  width: 80px;
  z-index: 0;
`;

const SelectContainer = styled.div`
  display: inline-flex;
  position: relative;
  border: 1px solid ${Colors.LightGrey};
  height: 29px;
  width: 137px;
  margin: 0 10px;
`;

const InputLabel = styled.label`
  top: 3px;
  left: 8px;
  font-weight: bold;
  font-size: ${FontSize.Small};
  background-color: transparent;
  z-index: 0;
`;

const Dropdown = styled.select`
  border: 0;
  outline: none;
  font-size: ${FontSize.ZebraTitle};
  background-color: transparent;
  width: 100%;
  padding-left: 5px;
  z-index: 0;
`;

const Title = styled.h1`
  font-size: 3em;
  font-weight: normal;
  margin: 0;
  padding: 0;
  margin-right: 20px;
  margin-left: 25px;
`;

const IncidentsMapControl: React.FC = () => {
  const {
    loading,
    company,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    selectedCompany,
    setSelectedCompany,
  } = useContext(IncidentsMapContext);

  const { user } = useContext(BasicDataContext);

  const CustomInput = (
    props: React.HTMLProps<HTMLInputElement>,
    ref: React.Ref<HTMLInputElement>
  ) => (
    <input
      style={{
        border: 0,
        backgroundColor: 'transparent',
        outline: 'none',
        width: '127px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        fontSize: `${FontSize.ZebraTitle}`,
        zIndex: 0,
        height: '100%',
      }}
      {...props}
    ></input>
  );

  const handleSelectedCompanyChanged = (companyStr: string) => {
    if (companyStr === 'Alla bolag') {
      setSelectedCompany(undefined);
    } else {
      setSelectedCompany(company);
    }
  };

  return (
    <>
      <Container>
        <Title>Vägolyckor</Title>
        <SelectContainer>
          <DatePicker
            disabled={loading ? true : false}
            selected={startDate}
            maxDate={endDate}
            onChange={(date: Date) => setStartDate(date)}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode='select'
            customInput={React.createElement(React.forwardRef(CustomInput))}
          />
        </SelectContainer>
        <SelectContainer>
          <DatePicker
            maxDate={new Date(Date.now())}
            disabled={loading ? true : false}
            selected={endDate}
            onChange={(date: Date) => setEndDate(date)}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode='select'
            customInput={React.createElement(React.forwardRef(CustomInput))}
          />
        </SelectContainer>
        <SelectContainer style={{ minWidth: '220px' }}>
          <Dropdown
            disabled={loading ? true : false}
            defaultValue={selectedCompany ? selectedCompany.name : 'Alla bolag'}
            onChange={(e) => handleSelectedCompanyChanged(e.target.value)}
          >
            <option>Alla bolag</option>
            {company && <option key={company.id}>{company.name}</option>}
          </Dropdown>
        </SelectContainer>
      </Container>
    </>
  );
};

export default IncidentsMapControl;
