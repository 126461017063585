//import BlenderProBook from './335787aa6f57d71cabe8eb5dc89c6d6b.woff';
//import BlenderProBook2 from './335787aa6f57d71cabe8eb5dc89c6d6b.woff2';
//import BlenderProBold from './a082fd3df68a0b54e0d4d794bc38d268.woff';
//import BlenderProBold2 from './a082fd3df68a0b54e0d4d794bc38d268.woff2';
import { createGlobalStyle } from 'styled-components';

export const GlobalFonts = createGlobalStyle`
  
  body,
  html,
  #root {
    height: 100%;
    width: 100%;
  }

  body {
    padding: 0;
    margin: 0;
    font-family: 'Blender Pro';
    overflow-y: scroll;
  }

  button {
    font-family: 'Blender Pro'
    }

    input, select {
      
  font-family: 'Blender Pro' !important;
    }
`;
