export const Colors = {
  Red: '#e30514',
  White: '#ffffff',
  WhiteShade: '#f6f6f6',
  DividerGrey: '#EBEBEB',
  LightGrey: '#d0d0d0',
  DarkGrey: '#828282',
  DarkGreyContrast: '#595959',
  Black: '#000000',
  NearBlack: '#0b0b0b',
  Golden: '#F7B500',
  Hover: '#fef6e1',
  Green: 'green',
  Background: 'white',
};

export const FontSize = {
  Small: '12px',
  Medium: '14px',
  ZebraTitle: '16px',
  Large: '18px',
  Title: '24px',
  H1: '30px',
  H2: '24px',
  H3: '18px',
};

export const FirstColumnWidth = {
  Default: 240,
  Small: 140,
};

export const FontFamily = {};

export const Margins = {
  Small: '10px',
  Medium: '20px',
  Large: '30px',
  ExtraLarge: '35px',
  Double: '40px',
};
