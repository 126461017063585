import { RefObject, useEffect, useState } from 'react';

type GoogleMapProps = {
  mapRef: RefObject<HTMLDivElement>;
  zoom: number;
  useZoomControl?: boolean;
  scrollwheel: boolean;
  center: google.maps.LatLng | google.maps.LatLngLiteral | undefined;
};

export const useGoogleMap = ({
  mapRef,
  zoom,
  scrollwheel,
  useZoomControl = true,
  center,
}: GoogleMapProps) => {
  const [map, setMap] = useState<google.maps.Map<HTMLDivElement> | undefined>(
    undefined
  );
  const [infoWindow, setInfoWindow] = useState<
    google.maps.InfoWindow | undefined
  >(new google.maps.InfoWindow({ disableAutoPan: true }));

  useEffect(() => {
    const createMap = () => {
      if (mapRef.current) {
        return new google.maps.Map(mapRef.current, {
          zoom: zoom,
          center: center,
          mapTypeControl: false,
          streetViewControl: false,
          rotateControl: false,
          scaleControl: true,
          fullscreenControl: false,
          panControl: false,
          zoomControl: useZoomControl,
          scrollwheel: scrollwheel,
          gestureHandling: 'greedy',
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          draggableCursor: 'pointer',
        });
      }
    };

    setMap(createMap());

    return () => {
      if (infoWindow) google.maps.event.clearInstanceListeners(infoWindow);
      setInfoWindow(undefined);
      setMap(undefined);
    };
  }, []);

  return { map, infoWindow };
};
